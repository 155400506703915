import React, {useEffect, useState} from "react";
import './blerjet.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import BlerjetModal from "./blerjetModal";
import { connect } from "react-redux";
// import {
//    setUserId
//   } from "./../../../redux/Functions/actions";

const Blerjet = (props) => {

    const [loading, setLoading] = useState(true);
    const [ Transactions, setTransactions] = useState([]);
    const [ openModal , setOpenModal] = useState(false);
    const [isCustomer, setCustomer] = useState(false);
    const [furnitorData, setfurnitorData ] = useState([]);
    const [products, setProducts] = useState([]);

 const getData = () => {
     axios.get('/buyings').then(
         data => {
             console.log('buyings',data.data);
             setTransactions(data.data);
            //  setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
     axios.get('/buyings/furnitors').then(
         data => {
            //  console.log('Transactions',data.data);
             setfurnitorData(data.data);
            //  setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
     axios.get('/products').then(
         data => {
            //  console.log('Transactions',data.data);
            setProducts(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModalFurnitor = (data, fn) => {
    // e.preventDefault();
    render(<BlerjetModal data={data} furnitorData={furnitorData.length > 0 && furnitorData} products={products} getData={fn}  redux={props?.reduxData} type='edit'/>)
}
const handleAddFurnitorModal = (data, fn) => {
    // e.preventDefault();
    render(<BlerjetModal data={null} furnitorData={furnitorData}  products={products} getData={fn} redux={props?.reduxData} type='add'/>)
}
// const handleModalCustomer = (data, fn) => {
//     // e.preventDefault();
//     render(<KonsumatoretModal data={data} getData={fn}/>)
// }
// const handleAddCustomerModal = (data, fn) => {
//     // e.preventDefault();
//     render(<KonsumatoretModal data={null} getData={fn} type='add'/>)
// }


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='blerjet'>
        <div className='title' style={{left: props?.menu ? '20%' : '0'}}>
            <h3>Blerjet</h3>
        </div>
        {/* <div className='stockNav'>
            <button className={isCustomer === false && 'active'}onClick={()=>setCustomer(false)}>Furnitorët</button>
            <button className={isCustomer === true && 'active'} onClick={()=>setCustomer(true)}>Klientat</button>
        </div> */}
       { isCustomer === false && <div className='listimi w-100'>
        <div className='list-item-head ' style={{left: props?.menu ? '22%' : '2%'}}> 
        <div className="row">

            <div className='col-1 pl-4'>ID</div>
            <div className='col-3 '>Emri</div>
            <div className='col-2'>Totali</div>
            <div className='col-2'>Data</div>
            <div className='col-3'>Statusi</div>

            <div className='col-1'>
                </div>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {Transactions?.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {Transactions?.length > 0 && <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}}>
            {Transactions?.length > 0 && Transactions?.map((el, index)=> {
                return (
                <div className='list-item' key={index}>
                    <div className="row">
                        <div className='col-1 pl-4'>{el.id}</div>
                        <div className='col-3'>{el.furnitor_name}</div>
                        <div className='col-2'>{el.total + ' EUR'}</div>
                        <div className='col-2'>{el.payment_status === 'Inicuar' ? el.created_at.slice(0,10) : el.payment_date}</div>
                        <div className='col-3'>{el.payment_status}</div>
                        <div className='col-1'><button className='editButton' onClick={()=> handleModalFurnitor(el, getData)}><i className='fa fa-eye' /></button></div>
                    </div>
                </div>
                )
            })}
            </div>}
        </div>}
        { isCustomer === true ? <button className='addButton' >+</button>
        : <button className='addButton' onClick={()=>handleAddFurnitorModal('palidhje', getData)} >+</button> }
    </div>
    )

}

const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading ,
      loggedIn: state.data.loggedIn,
      auth: state.data.auth,
      reduxData: state.data,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    //   setUserId: (id) => dispatch(setUserId(id)),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Blerjet);