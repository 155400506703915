import React, {useEffect, useState} from "react";
import './products.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import ProductsModal from "./productsModal";
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../../redux/Functions/actions";

const Products = (props) => {

    const [loading, setLoading] = useState(true);
    const [ productdata, setproductData] = useState([]);
    const  [ openModal , setOpenModal] = useState(false);

 const getData = () => {
     axios.get('/products').then(
         data => {
             console.log('productData',data.data);
             setproductData(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModal = (data, fn) => {
    // e.preventDefault();
    render(<ProductsModal data={data} getData={fn} />)
}
const handleAddModal = (data, fn) => {
    // e.preventDefault();
    render(<ProductsModal data={null} getData={fn} type='add'/>)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='products' style={{left: props.menu ? '20%' : '2%' }}>
        <div className='title' style={{left: props.menu ? '20%' : '0' }}>
            <h3>Produktet</h3>
        </div>
        <div className='listimi w-100' >
        <div className='list-item-head row ' style={{left: props.menu ? '22%' : '2%'}}> 
            <div className='col-3'>Emri</div>
            <div className='col-2'>Cmimi (shitjes)</div>
            <div className='col-2'>Cmimi (blerjes)</div>
            <div className='col-2 text-right '>Kapaciteti</div>
            <div className='col-2'>Përditësuar</div>
            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        <div className="tableContainer"  style={{left: props.menu ? '22%' : '2%' , width:  props.menu ? '78%' : '99%' }}>
            {productdata && productdata?.map((el, index)=> {
                return (

                <div className='list-item row' key={index}>
                    <div className='col-3'>{el.name_al}</div>
                    <div className='col-2'>{el.selling_price + ' EUR'}</div>
                    <div className='col-2'>{el.buying_price + ' EUR'}</div>
                    <div className='col-2 text-right'>{el.capacity + ' T/mauj'}</div>
                    <div className='col-2'>{el?.updated_at?.slice(0,10)}</div>
                    <div className='col-1'><button className='editButton' onClick={()=> handleModal(el, getData)}><i className='fa fa-eye' /></button></div>
                </div>
                )
            })}
            </div>
        </div>
        <button className='addButton' onClick={()=>handleAddModal(null, getData)} >+</button>
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
      // loadingOff: () => dispatch(loadingOff()),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Products);
// export default Products