import React, {useEffect, useState} from "react";
import './partners.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import PartnersModal from "./partnersModal";
import { connect } from "react-redux";

// import {
//    setUserId
//   } from "./../../../redux/Functions/actions";

const Partners = (props) => {

    const [loading, setLoading] = useState(true);
    const [ productdata, setproductData] = useState([]);
    const  [ openModal , setOpenModal] = useState(false);

 const getData = () => {
     axios.get('/partners').then(
         data => {
             console.log('productData',data.data);
             setproductData(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModal = (data, fn) => {
    // e.preventDefault();
    render(<PartnersModal data={data} getData={fn}/>)
}
const handleAddModal = (data, fn) => {
    // e.preventDefault();
    render(<PartnersModal data={null} getData={fn} type='add'/>)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='partners'>
        <div className='title' style={{left: props?.menu ? '20%' : '0'}}>
            <h3>Partnerët</h3>
        </div>
        <div className='listimi w-100'>
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}}> 
            <div className='col-4'>Emri</div>
            <div className='col-4'>URL</div>
            <div className='col-3'></div>

            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}}>
        {productdata.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {productdata && productdata?.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-4'>{el.name}</div>
                    <div className='col-4'>{el.url}</div>
                    <div className='col-3'></div>
                    <div className='col-1'><button className='editButton' onClick={()=> handleModal(el, getData)}><i className='fa fa-eye' /></button></div>
                </div>
                )
            })}
            </div>
        </div>
        <button className='addButton' onClick={()=>handleAddModal(null, getData)} >+</button>
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading ,
      loggedIn: state.data.loggedIn,
      auth: state.data.auth,
      reduxData: state.data,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    //   setUserId: (id) => dispatch(setUserId(id)),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Partners);
// export default Partners;