import axios from "../../../axios";
import React, {useState} from "react";
import { toast } from "react-toastify";
import Silueta from '../../../images/logo-only.png';

function PartnersModal(props) {
//   console.log('po hapna', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);
  const [image ,setImage] = useState();
  const [isImageEdit, setImageEdit] =useState(false);


const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/partners/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e partnerit janë përditësuar me sukses!');
              setOpen(false);
            //   window.history.go('/admin/partners');
          }
      ).catch(err => {
        toast.error('Të dhënat e partnerit nuk janë përditësuar!');
          console.log(err);
        });
      
    }
const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
      axios.post('/partners/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e partnerit janë ruajtur me sukses!');
              setOpen(false);
            //   window.history.go('/admin/partners');
          }
      ).catch(err => {
        toast.error('Të dhënat e partnerit nuk janë ruajtur!');
          console.log(err);
        });
      
    }
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/partners/?id='+props?.data?.id)
    .then(data=>{
        props?.getData();
        toast.success('Të dhënat e partnerit janë fshier me sukses!');
        setOpen(false);
        // window.history.go('/admin/partners');
    })
    .catch(err=> {
        toast.error('Të dhënat e partnerit nuk janë fshirë!');
        console.log(err);
    })
}
const handleImage = async () => {

    let formData = new FormData()
    formData.append('image', image);
    
    axios.post('/partners/image/'+ props?.data?.id , formData)
    .then(data => {
        setImageEdit(false);
        props?.getData();
        toast.success('Imazhi është ruajtur me sukses!');
        setOpen(false);
        // window.location.reload();

    })
    .catch(err => {
        toast.error('Imazhi nuk është ruajtur!');
         console.log('error',err);
        });
}

const pic_url = e => {
    const ud = Object.assign({}, image);
    let img = e.target.files[0]
    setImage(img)
}

const name = (e) => {
    const ud = Object.assign({}, data);
    ud.name = e.target.value
    setData(ud);
}
const url = (e) => {
    const ud = Object.assign({}, data);
    ud.url = e.target.value
    setData(ud);
}




console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row pl-5'>
                <div className='col-10 pl-5'>
                    {props?.type === 'add' && <h3>Shto Partner të ri!</h3>}

           { props?.data && <h3>Emri: {' ' +props?.data?.name}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-warning' onClick={()=> setEdit(!isEdit)}><i className={ isEdit ? 'fa fa-times' :'fa fa-edit'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>
            <div className='row pl-5'>
          { props?.type !== 'add'  &&   <div className='col-3 pl-5'>
                    <img src={props?.data?.pic_url != null ? process.env.REACT_APP_UPLOADS + props?.data?.pic_url : Silueta }  alt='Product Image' className='product-img' />
                    <button className='change-image-btn' onClick={()=> setImageEdit(!isImageEdit)}><i className={isImageEdit == false ? 'fa fa-edit' : 'fa fa-times'} /></button>
                </div>}
                <div className='col-9'>{isImageEdit && <>
                <input type='file' onChange={pic_url} />
                <button className='btn btn-success' onClick={handleImage} disabled={image == null || image == '' ? true : false}>Ruaj Imazhin!</button>
                </>} </div>
            </div>
            {isdelete === false && 
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision}>
                <div className='form-group'>
                <label>Emri:</label>  
                <input type='text' onChange={name} class="form-control" placeholder='emri' defaultValue={props?.data?.name} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>URL: </label> 
                <input type='text'onChange={url} class="form-control" placeholder='webfaqja' defaultValue={props?.data?.url} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                                
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
            }
                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Shto Partnerin! </button>
                }
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni partnerin me emërin: {'  ' + props?.data?.name}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
        </div>
    </div>
      )
  } else return null

}

export default PartnersModal;