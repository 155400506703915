import axios from "../../../axios";
import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import './progress.scss';
import ProgressBar from "@ramonak/react-progress-bar";

function KartelaFurnitoritModal(props) {
  console.log('KardelaModalProps', props);
  const [isOpen,setOpen ] = useState(true);
  const [data, setData] = useState([]);
  const [historyData, sethistoryData] = useState([]);

const getCardOverall = () => {
    axios.get(`furnitors/card/${props.data.id}/totals`).then(data => {
        console.log('getCardOverall',data.data);
        setData(data.data);
    }).catch(err => {
        console.log(err);
    })
}
const getCardDetails = () => {
    axios.get(`furnitors/card/${props.data.id}/all`).then(data => {
        console.log('getCardDetails',data.data);
        sethistoryData(data.data);
    }).catch(err => {
        console.log(err);
    })
}

useEffect(()=>{
    if(props.data) {
        getCardOverall();
        getCardDetails();
    }
},[])





console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className="progress-wrapper">
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row p-4'>
                <div className='col-12 pl-5 pr-5'>

           { props?.data && <h3>Kartela e Furnitorit: {' ' +props?.data?.full_name}</h3>} <br />
           {'SysID:' + props.data.id + ' | ' + 'Nr. Leternjoftimit : ' + props.data.id_nr }
                </div>
            <div className="col-12 pl-5 pr-5 pt-3" >
           {/* { data.length > 0 &&  <ProgressBar 
                completed={data[0]?.payed} 
                maxCompleted={data[0]?.total} 
                className="progress-wrapper"
                barContainerClassName="progress-container"
                completedClassName="progress-barCompleted"
                labelClassName="progress-label"
                customLabel={`   ${Number(data[0].payed)} Eur`}
                />} */}
                {data && data[0] &&
                 <div class="progress-container text-right bg-success" style={{height: '40px'}}>
                    <div class="progress-bar bg-danger" style={{width:`${data[0]?.unpayedPerc + '%'}`, height: '40px'}} >{ data[0].toPay}</div>
                 <label className="progress-label"> { data[0].payed}</label> 
                </div> 
                }
            </div>
            <div className="col-12 pl-5 pr-5 pt-3">
            <div className="row list-item-head">
                                <div className="col-3">Data</div>
                                <div className="col-3">Shuma</div>
                                <div className="col-3">Metoda Pageses</div>
                                <div className="col-3">Statusi</div>

                        </div>
                {historyData && historyData.map(el => {
                    return(
                        <div className="row list-item">
                                <div className={"col-3"}>{el.created_at.slice(0,10)}</div>
                                <div className={"col-3"}>{el.total}</div>
                                <div className={"col-3"}>{el.payment_method}</div>
                                <div className={el.payment_status === 'Paguar' ? 'col-3 rounded-pill bg-success p-1 text-light pl-4' : 'col-3 bg-danger rounded-pill p-1 text-light pl-4'}>{el.payment_status}</div>

                        </div>
                    )
                })}
            </div>
            </div>
        </div>
    </div>
    </div>
      )
  } else return null

}

export default KartelaFurnitoritModal;