import React, {useEffect, useState} from "react";
import './bashkpuntoret.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import KonsumatoretModal from "./konsumatoretModal";
import FurnitoretModal from "./furnitoretModal";
import KartelaFunitoritModal from "./kartelaFurnitoritModal";
import KartelaKonsumatoritModal from "./kartelaKonsumatoritModal";
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../../redux/Functions/actions";

const Bashkpuntoret = (props) => {

    const [loading, setLoading] = useState(true);
    const [ furnitorData, setfurnitorData] = useState([]);
    const [ openModal , setOpenModal] = useState(false);
    const [isCustomer, setCustomer] = useState(false);
    const [stockCustomerData, setStockCustomerData ] = useState([]);
    const [furnitorCard, setFurnitorCard ] = useState([]);
    const [card, setCard] = useState(false);
 const getData = () => {
     axios.get('/furnitors').then(
         data => {
             console.log('furnitorData',data.data);
             setfurnitorData(data.data);
            //  setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
        }
const getCustomerData = () => {
     axios.get('/customers').then(
         data => {
            //  console.log('furnitorData',data.data);
             setStockCustomerData(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

const getFurnitorCard = () => {
    axios.get('statistics/furnitor/card').then(data => {
        console.log('furnitorCard',data.data);
        setFurnitorCard(data.data);
    }).catch(err => console.log(err))
}

let auth = window.localStorage.getItem('gplustoken');
    useEffect(()=>{

      if (auth !== null || auth !== undefined){  
        getData();
        getCustomerData();
        getFurnitorCard();
    }
    },[]);
    
const handleModalFurnitor = (data, fn) => {
    // e.preventDefault();
    render(<FurnitoretModal data={data} getData={fn} />)
}
const handleAddFurnitorModal = (data, fn) => {
    // e.preventDefault();
    render(<FurnitoretModal data={null} getData={fn} type='add'/>)
}
const handleModalCustomer = (data, fn) => {
    // e.preventDefault();
    render(<KonsumatoretModal data={data} getData={fn}/>)
}
const handleAddCustomerModal = (data, fn) => {
    // e.preventDefault();
    render(<KonsumatoretModal data={null} getData={fn} type='add'/>)
}
const handleFurnitorViewCard = (data) => {
    render(<KartelaFunitoritModal data={data} />)
}
const handleCustomerViewCard = (data) => {
    render(<KartelaKonsumatoritModal data={data} />)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='bashkpunetoret' style={{left: props?.menu ? '20%' : '0'}}>
        <div className='title' style={{left: props?.menu ? '20%' : '0'}}>
            <h3>Bashkpunotorët</h3>
        </div>
        <div className='stockNav'>
            <button className={isCustomer === false && 'active'}onClick={()=>setCustomer(false)}>Furnitorët</button>
            <button className={isCustomer === true && 'active'} onClick={()=>setCustomer(true)}>Klientat</button>
        </div>
       { isCustomer === false && <div className='listimi w-100'>
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}} > 
            <div className='col-4'>Emri</div>
            <div className='col-3'>Nr. Telefonit</div>
            <div className='col-3'>Nr. Leternjoftimit</div>

            <div className='col-2'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {furnitorData && furnitorData?.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {furnitorData && furnitorData?.length > 0 && <div className="tableContainer"  style={{left: props?.menu ? '22%' : '2%'}}>
            {furnitorData && furnitorData?.length > 0 && furnitorData?.map((el, index)=> {
                return (
                <div className='list-item row ' key={index}>
                    <div className='col-4'>{el.full_name}</div>
                    <div className='col-3'>{el.phone}</div>
                    <div className='col-3'>{el.id_nr}</div>
                    <div className='col-2'>
                            <button className='editButton' onClick={()=> handleModalFurnitor(el, getData)}><i className='fa fa-eye' /></button>
                            <button className='editButton' onClick={()=> handleFurnitorViewCard(el)}><i class="fa fa-credit-card" aria-hidden="true"></i></button>
                    </div>
                </div>
                )
            })}
            </div>}
        </div>}
       { isCustomer === true && <div className='listimi w-100' >
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}}> 
            <div className='col-4'>Emri Kompanisë</div>
            <div className='col-3'>Personi Kontaktues</div>
            <div className='col-3'>Telefoni</div>

            <div className='col-2'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {stockCustomerData.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {stockCustomerData.length > 0  && <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}} >
            {stockCustomerData.length > 0 && stockCustomerData?.map((el, index)=> {
                return (
                <div className='list-item row' key={index} >
                    <div className='col-4'>{el.company_name}</div>
                    <div className='col-3'>{el.contact_point}</div>
                    <div className='col-3'>{el.cp_phone}</div>
                    <div className='col-2'>
                        <button className='editButton' onClick={()=> handleModalCustomer(el, getData)}><i className='fa fa-eye' /></button>
                        <button className='editButton' onClick={()=> handleCustomerViewCard(el)}><i class="fa fa-credit-card" aria-hidden="true"></i></button>
                    </div>
                </div>
                )
            })}
            </div>}
        </div>}
       { card === true && <div className='listimi w-100'  >
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}}> 
            <div className='col-4'>Emri Kompanisëeeeee</div>
            <div className='col-4'>Personi Kontaktues</div>
            <div className='col-3'>Telefoni</div>

            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {stockCustomerData.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {stockCustomerData.length > 0  && <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}}>
            {stockCustomerData.length > 0 && stockCustomerData?.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-4'>{el.company_name}</div>
                    <div className='col-4'>{el.contact_point}</div>
                    <div className='col-3'>{el.cp_phone}</div>
                    <div className='col-1'>
                        <button className='editButton' onClick={()=> handleModalCustomer(el, getData)}><i className='fa fa-eye' /></button>
                    </div>
                </div>
                )
            })}
            </div>}
        </div>}
        { isCustomer === true ? <button className='addButton' onClick={()=>handleAddCustomerModal('palidhje', getData)} >+</button>
        : <button className='addButton' onClick={()=>handleAddFurnitorModal('palidhje', getData)} >+</button> }
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
      // loadingOff: () => dispatch(loadingOff()),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Bashkpuntoret);
// export default Bashkpuntoret;