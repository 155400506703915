import React, {useEffect, useState} from "react";
import './users.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import UserModal from "./userModal";
import { connect } from "react-redux";
// import {
//    setUserId
//   } from "./../../../redux/Functions/actions";

const Users = (props) => {

    const [loading, setLoading] = useState(true);
    const [ userdata, setUserData] = useState([]);
    const  [ openModal , setOpenModal] = useState(false);

 const getData = () => {
     axios.get('/users/all').then(
         data => {
             console.log('userData',data.data);
             setUserData(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModal = (data, fn) => {
    // e.preventDefault();
    render(<UserModal data={data} getData={fn} />)
}
const handleAddModal = (data, fn) => {
    // e.preventDefault();
    render(<UserModal data={null} getData={fn} type='add'/>)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='users'>
        <div className='title' style={{left: props?.menu ? '20%' : '0'}}>
            <h3>Shfrytëzuesit</h3>
        </div>
        <div className='listimi w-100'>
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}}> 
            <div className='col-3'>Emri</div>
            <div className='col-3'>Emaili</div>
            <div className='col-3'>Roli</div>
            <div className='col-1'>Statusi</div>
            <div className='col-2'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}}>
            {userdata && userdata.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-3'>{el.full_name}</div>
                    <div className='col-3'>{el.email}</div>
                    <div className='col-3'>{el.role === 1 ? 'Adminstrator' : 'Shites'}</div>
                    <div className='col-1'>{el.active === 1 ? 'Aktiv' : 'Passiv'}</div>
                    <div className='col-1'><button className='editButton' onClick={()=> handleModal(el, getData)}><i className='fa fa-eye' /></button></div>
                </div>
                )
            })}
            </div>
        </div>
        <button className='addButton' onClick={()=>handleAddModal(null, getData)} >+</button>
    </div>
    )

}

const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading ,
      loggedIn: state.data.loggedIn,
      auth: state.data.auth,
      reduxData: state.data,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    //   setUserId: (id) => dispatch(setUserId(id)),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Users);

// export default Users