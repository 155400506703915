import axios from "../../../axios";
import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import Silueta from '../../../images/logo-only.png';

function KonsumatoretModal(props) {
//   console.log('po hapna', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);
  const [userData ,setuserData] = useState();
  const [productData, setproductData] =useState();


const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/customers/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e klientit janë përditësuar me sukses!');
              setOpen(false);
          }
      ).catch(err => {
        toast.error('Të dhënat e klientit nuk janë përditësuar!');
          console.log(err);
        });
      
    }


const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
      axios.post('/customers/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e klientit janë ruajtur me sukses!');
              setOpen(false);
          }
      ).catch(err => {
        toast.error('Të dhënat e klientit nuk janë ruajtur!');
          console.log(err);
        });
      
    }
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/customers/?id='+props?.data?.id)
    .then(data=>{
        toast.success('Të dhënat e klientit janë fshier me sukses!');
        setOpen(false);
    })
    .catch(err=> {
        toast.error('Të dhënat e klientit nuk janë fshirë!');
        console.log(err);
    })
}

const company_name = (e) => {
    const ud = Object.assign({}, data);
    ud.company_name = e.target.value
    setData(ud);
}
const contact_point = (e) => {
    const ud = Object.assign({}, data);
    ud.contact_point = e.target.value
    setData(ud);
}
const cp_phone = (e) => {
    const ud = Object.assign({}, data);
    ud.cp_phone = e.target.value
    setData(ud);
}
const cp_email = (e) => {
    const ud = Object.assign({}, data);
    ud.cp_email = e.target.value
    setData(ud);
}
const phone = (e) => {
    const ud = Object.assign({}, data);
    ud.phone = e.target.value
    setData(ud);
}
const email = (e) => {
    const ud = Object.assign({}, data);
    ud.email = e.target.value
    setData(ud);
}
const address = (e) => {
    const ud = Object.assign({}, data);
    ud.address = e.target.value
    setData(ud);
}
const bank_acc = (e) => {
    const ud = Object.assign({}, data);
    ud.bank_acc = e.target.value
    setData(ud);
}
const bank_code = (e) => {
    const ud = Object.assign({}, data);
    ud.bank_code = e.target.value
    setData(ud);
}
const swift_code = (e) => {
    const ud = Object.assign({}, data);
    ud.swift_code = e.target.value
    setData(ud);
}
const city = (e) => {
    const ud = Object.assign({}, data);
    ud.city = e.target.value
    setData(ud);
}
const state = (e) => {
    const ud = Object.assign({}, data);
    ud.state = e.target.value
    setData(ud);
}
const bussines_id = (e) => {
    const ud = Object.assign({}, data);
    ud.bussines_id = e.target.value
    setData(ud);
}




console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row pl-5'>
                <div className='col-10 pl-5'>
                    {props?.type === 'add' && <h3>Shto Klient të ri!</h3>}

           { props?.data && <h3>Klienti: {' ' +props?.data?.company_name}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-warning' onClick={()=> setEdit(!isEdit)}><i className={ isEdit ? 'fa fa-times' :'fa fa-edit'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>

            {isdelete === false && 
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision}>
                <div className='form-group'>
                <label>Emri i Kompanisë:</label>  
                <input  onChange={company_name} class="form-control" placeholder='elementi' defaultValue={props?.data?.company_name} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Numri i Biznesit:</label>  
                <input  onChange={bussines_id} class="form-control" placeholder='elementi' defaultValue={props?.data?.bussines_id} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Adresa:</label>  
                <input  onChange={address} class="form-control" placeholder='elementi' defaultValue={props?.data?.address} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Telefoni:</label>  
                <input  onChange={phone} class="form-control" placeholder='elementi' defaultValue={props?.data?.phone} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>E-maili:</label>  
                <input  onChange={email} class="form-control" placeholder='elementi' defaultValue={props?.data?.email} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Qyteti:</label>  
                <input  onChange={city} class="form-control" placeholder='elementi' defaultValue={props?.data?.city} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Shteti:</label>  
                <input  onChange={state} class="form-control" placeholder='elementi' defaultValue={props?.data?.state} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Gjirollogaria:</label>  
                <input  onChange={bank_acc} class="form-control" placeholder='elementi' defaultValue={props?.data?.bank_acc} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Kodi Bankar:</label>  
                <input  onChange={bank_code} class="form-control" placeholder='elementi' defaultValue={props?.data?.bank_code} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Swift Kodi:</label>  
                <input  onChange={swift_code} class="form-control" placeholder='elementi' defaultValue={props?.data?.swift_code} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Personi Kontaktues:</label>  
                <input  onChange={contact_point} class="form-control" placeholder='elementi' defaultValue={props?.data?.contact_point} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Nr Telefonit (Personit Kontaktues):</label>  
                <input  onChange={cp_phone} class="form-control" placeholder='elementi' defaultValue={props?.data?.cp_phone} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Email (Personit Kontaktues):</label>  
                <input  onChange={cp_email} class="form-control" placeholder='elementi' defaultValue={props?.data?.cp_email} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
           
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
            }
                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Shto Klientin! </button>
                }
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni përkthimin me emërin: {'  ' + props?.data?.company_name}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
        </div>
    </div>
      )
  } else return null

}

export default KonsumatoretModal;