import React , {useState} from "react";
import './login.scss';
import Logo from '../../images/gplus_logo-05.png';
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import {
   loggMeIN, 
   setAuth,
   setUserId,
   setRole
  } from "../../redux/Functions/actions";
const Login = (props) => {

    const [userData, adduserdata] = useState({});
    const [errors, addErrors] = useState('');

    const username = e => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        console.log('', e.target.value)
        adduserdata(ud);
    }

    const password = e => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        // console.log(e.target.value)
        adduserdata(ud);
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        axios.post('/login', userData)
        .then((res) => {
            console.log('res.data.token', res.data.token);
            if (res.data.token) {
               window.localStorage.setItem('gplustoken', res.data.token);
               setTimeout(()=>{
                   let token = window.localStorage.getItem('gplustoken')
                   if(token !== null) {
                       props.setAuth(token);
                       props.loggMeIN();
                       var decoded = jwt_decode(token);
                        
                    //    console.log('decodedShit',decoded);
                        props.setRole(decoded.role);
                       props.setUserId(decoded.id);
                       window.location.reload();
                   }
                },300)

            } else {
                addErrors(res.data.error);
            }


        })
        .catch(err => {
            console.log('error incoming', err);
        })

    }
    return(
        <div className='login'>
            <img src={Logo} alt='logo' width='160px'/>
            <div className='login-wraper'>
                <form onSubmit={handeleSubmit}>
                    <input type='email' placeholder='email' onChange={username}></input>
                    <input type='password' placeholder='Password' onChange={password}></input>
                    <button type='submit'>Login</button>
                </form>
            </div>

        </div>

    )
}

const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading ,
      loggedIn: state.data.loggedIn,
      auth: state.data.auth
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      setAuth: (auth) => dispatch(setAuth(auth)),
      setRole: (role) => dispatch(setRole(role)),
      setUserId: (id) => dispatch(setUserId(id)),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Login);