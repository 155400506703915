import React, {useEffect, useState} from "react";
import './stock.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import StockModal from "./stockModal";
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../../redux/Functions/actions";

const Stock = (props) => {

    const [loading, setLoading] = useState(true);
    const [ stockData, setstockData] = useState([]);
    const  [ openModal , setOpenModal] = useState(false);
    const [isHistory, setHistory] = useState(false);
    const [stockHistoryData, setStockHistoryData ] = useState([]);

 const getData = () => {
     axios.get('/stock/overall').then(
         data => {
             console.log('stockData',data.data);
             setstockData(data.data);
            //  setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
     axios.get('/stock/all').then(
         data => {
            //  console.log('stockData',data.data);
             setStockHistoryData(data.data);
             setLoading(false);
         }
     ).catch(err => { console.log('Errori',err)});
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModal = (data, fn) => {
    // e.preventDefault();
    render(<StockModal data={data} getData={fn}/>)
}
const handleAddModal = (data, fn) => {
    // e.preventDefault();
    render(<StockModal data={null} getData={fn} type='add'/>)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='stock' style={{width: '80%', left: props.menu ? 'calc(1vw + 19%)' : '2%' }}>
        <div className='title' style={{ left: props.menu ? 'calc(1vw + 19%)' : '0' }}>
            <h3>Stoku</h3>
        </div>
        <div className='stockNav' style={{ left: props.menu ? '21%' : '2%' }}>
            <button className={isHistory === false && 'active'}onClick={()=>setHistory(false)}>Pergjithshem</button>
            <button className={isHistory === true && 'active'} onClick={()=>setHistory(true)}>Historiku</button>
        </div>
       { isHistory === false && <div className='listimi w-100'>
        <div className='list-item-head row' style={{ left: props.menu ? 'calc(1vw + 21%)' : '2%' }}> 
            <div className='col-4'>Produkti</div>
            <div className='col-4'>Sasia (kg)</div>
            <div className='col-3'>Perditesimi i Fundit</div>

            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {stockData?.length == 0 && <div className='row text-center'>Ska Shenime</div>}
        {stockData && <div className="tableContainer"  style={{ left: props.menu ? '21%' : '2%' }}> 
            {stockData?.length > 0 && stockData?.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-4'>{el.name_al}</div>
                    <div className='col-4'>{el.qty_kg + ' KG'}</div>
                    <div className='col-3'>{el.full_name}</div>
                    {/* <div className='col-1'><button className='editButton' onClick={()=> handleModal(el)}><i className='fa fa-eye' /></button></div> */}
                </div>
                )
            })}
            </div>}
        </div>}
       { isHistory === true && <div className='listimi w-100'>
        <div className='list-item-head row'  style={{ left: props.menu ? 'calc(1vw + 21%)' : '2%' }}> 
            <div className='col-4'>Produkti</div>
            <div className='col-4'>Sasia (kg)</div>
            <div className='col-3'>Perditesimi i Fundit</div>

            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        {stockHistoryData.length == 0 && <div className='row text-center'>Ska Shenime</div>}
        {stockHistoryData && <div className="tableContainer"  style={{ left: props.menu ? 'calc(1vw + 19%)' : '2%' }}> 
            {stockHistoryData && stockHistoryData?.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-4'>{el.name_al}</div>
                    <div className='col-4'>{el.qty_kg + ' KG'}</div>
                    <div className='col-3'>{el.full_name}</div>
                    <div className='col-1'><button className='editButton' onClick={()=> handleModal(el, getData)}><i className='fa fa-eye' /></button></div>
                </div>
                )
            })}
        </div>}
        </div>}
        <button className='addButton' onClick={()=>handleAddModal(null, getData)} >+</button>
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
      // loadingOff: () => dispatch(loadingOff()),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Stock);
// export default Stock;