import axios from "../../../axios";
import React, {useState} from "react";
import { toast } from "react-toastify";

function UserModal(props) {
//   console.log('po hapna', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);

const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/users/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e shfrytëzuesit janë përditësuar me sukses!');
              setOpen(false);
            //   window.history.go('/admin/users');
          }
      ).catch(err => {
        toast.error('Të dhënat e shfrytëzuesit nuk janë përditësuar!');
          console.log(err);
        });
      
    }
const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
      axios.post('/users/', data).then(
          data=> {
              console.log('goingData', data);
              setOpen(false);
              toast.success('Të dhënat e shfrytëzuesit janë ruajtur me sukses!');
              props?.getData();
            //   window.history.go('/admin/users');
          }
      ).catch(err => {
        toast.error('Të dhënat e shfrytëzuesit nuk janë ruajtur!');
          console.log(err);
        });
      
    }
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/users/?id='+props?.data?.id)
    .then(data=>{
        props?.getData();
        toast.success('Të dhënat e shfrytëzuesit janë fshier me sukses!');
        setOpen(false);
        // window.history.go('/admin/users');
    })
    .catch(err=> {
        toast.error('Të dhënat e shfrytëzuesit nuk janë fshirë!');
        console.log(err);
    })
}
const full_name = (e) => {
    const ud = Object.assign({}, data);
    ud.full_name = e.target.value
    setData(ud);
}
const email = (e) => {
    const ud = Object.assign({}, data);
    ud.email = e.target.value
    setData(ud);
}
const password = (e) => {
    const ud = Object.assign({}, data);
    ud.pass = e.target.value
    setData(ud);
}
const active = (e) => {
    const ud = Object.assign({}, data);
    ud.active = e.target.value
    setData(ud);
}
const role = (e) => {
    const ud = Object.assign({}, data);
    ud.role = e.target.value
    setData(ud);
}


console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row'>
                <div className='col-10'>
                    {props?.type === 'add' && <h3>Shto Shfrytëzues të ri!</h3>}

           { props?.data && <h3>Shfrytëzuesi: {' ' +props?.data?.full_name}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-warning' onClick={()=> setEdit(!isEdit)}><i className={ isEdit ? 'fa fa-times' :'fa fa-edit'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>
            {isdelete === false && 
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision}>
                <div className='form-group'>
                <label>Emri:</label>  
                <input type='text' onChange={full_name} class="form-control" placeholder='Emri dhe Mbiemri' defaultValue={props?.data?.full_name} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>Emaili: </label> 
                <input type='email'onChange={email} class="form-control" placeholder='filan@fisteku.com' defaultValue={props?.data?.email} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>Fjalëkalimi: </label> 
                <input type='password' onChange={password} class="form-control" placeholder='' defaultValue={props?.data?.email} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>Roli: </label> 
                <select class="form-control" onChange={role} defaultValue={props?.data?.role} disabled={isEdit || props?.type === 'add'? false : true}>
                    {props?.type === 'add' && <option value={null} selected disabled>Selekto</option> }
                    <option value={1}>Administrator</option>
                    <option value={2}>Shites</option>
                </select> 
                </div>
                <div className='form-group'>
                <label>Statusi: </label> 
                <select class="form-control" onChange={active} defaultValue={props?.data?.active} disabled={isEdit || props?.type === 'add'? false : true}>
                    {props?.type == 'add' && <option value={null} selected disabled  >Selekto</option> }
                    <option value={1}>Aktiv</option>
                    <option value={0}>Pasiv</option>
                </select>
                </div>
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
            }
                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Shto Shfrytësuesin! </button>
                }
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni shfrytëzuesin me emërin: {'  ' + props?.data?.full_name}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
        </div>
    </div>
      )
  } else return null

}

export default UserModal;