
const AdminMenuData = [
    {
        name_al: "Dashboardi",
        url: "/admin/dashboard",
        icon: "fa fa-home",
       id: 1
    },
    {
        name_al: "Bashkëpuntorët",
        url: "/admin/clients-furnitors",
        icon: "fa fa-home",
       id: 2
    },
    {
        name_al: "Produktet",
        url: "/admin/products",
        icon: "fa fa-home",
       id: 3
    },
    {
        name_al: "Stoku",
        url: "/admin/stock",
        icon: "fa fa-home",
       id: 4
    },
    {
        name_al: "Blerjet",
        url: "/admin/purchases",
        icon: "fa fa-home",
       id: 5
    },
    {
        name_al: "Shitjet",
        url: "/admin/sales",
        icon: "fa fa-home",
       id: 6
    },
    {
        name_al: "Statistikat",
        url: "/admin/statistics",
        icon: "fa fa-home",
       id: 7
    },
    {
        name_al: "Shfrytëzuesit",
        url: "/admin/users",
        icon: "fa fa-home",
       id: 8
    },
    {
        name_al: "Web Administrimi",
        url: "/admin/stock",
        icon: "fa fa-home",
       id: 9,
        more: [
            {
                name_al: "Partnerët",
                url: "/admin/partners",
                icon: "fa fa-home",
               id: 91
            },
            {
                name_al: "Stafi",
                url: "/admin/members",
                icon: "fa fa-home",
               id: 92
            },
            {
                name_al: "Përkthimet",
                url: "/admin/translations",
                icon: "fa fa-home",
               id: 93
            },
        ]
    },
   
  
]

export default AdminMenuData;