import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminMenuData from "./adminmenudata";
import './menu.scss';
import Logo from '../../images/gplus_logo-05.png';
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../redux/Functions/actions";
const AdminMenu = (props) => {
const [dropdown, setDropdown] = useState(false);
const [active, setActive] = useState(1);
    const logOut = () => {
        props.loggMeIN();
        props.rmAuth();
        window.localStorage.clear();
    }
let wwidth = window.innerWidth ;
console.log('width', wwidth);

if (props.menu === true) {

return(<>
      <button onClick={()=> props?.toggleMenu()} className="menuButton" style={{left:  wwidth > 750 ? ( props.menu ? '21%' : '10px') : '10px' }}>{props.menu ? <i class="fa fa-chevron-left"></i> : <i class="fa fa-bars"></i>}</button>
    <div className='admin-menu' style={{ transition: '1s ease-in-out'}}>
        <img src={Logo} alt='logo' width='160px'/>
        <ul>
            {AdminMenuData.map((el, index)=> {
                if (el?.more?.length > 0) {
                    return(<>
                    <i className={!dropdown ? "fa fa-chevron-down" : "fa fa-chevron-right"} style={{position: 'absolute', left: '23px', paddingTop:'15px'}} key={index} />
                         <Link to={{}} key={index} onClick={() => {setDropdown(!dropdown); setActive(el.id);}}><li className={dropdown === true && 'active'}>{'  '+el.name_al}</li>  </Link>

                         {dropdown === true && <div className="subMenuItems"> { el.more.map((subel,index)=> {
                             return  <Link to={subel.url} key={'sub-'+index} onClick={() => setActive(subel.id)} ><li className={active === subel.id && 'active'} >{subel.name_al}</li>  </Link>

                         }) } </div>}
                         </>
                         )
                } else 
                return <Link to={el.url} id={"menu-item-"+index} onClick={() => setActive(el.id)} key={index}><li className={active === el.id && 'active'}>{el.name_al}</li>  </Link>
            })}
            <Link to={'/homepage'} onClick={logOut}><li>Log Out</li>  </Link>
        </ul>
    </div>
    </>)
} else {
  return(
    <button onClick={()=> props?.toggleMenu()} className="menuButton" style={{left:  wwidth > 750 ? ( props.menu ? '21%' : '10px') : '10px' }} >{props.menu ? <i class="fa fa-chevron-left"></i> : <i class="fa fa-bars"></i>}</button>
  )
}

}

const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
      // loadingOff: () => dispatch(loadingOff()),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
// export default AdminMenu;