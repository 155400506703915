import React, {useEffect, useState, useRef} from "react";
import './statistics.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../../redux/Functions/actions";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, LineElement } from 'chart.js';
import { Bar, Line, Pie, PolarArea } from 'react-chartjs-2';
ChartJS.register(
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,

  )
  


const Statistics = (props) => {

    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [stockData, setstockData] = useState([]);


  // start declarin stat states
    const [furnitorsTop10, setfurnitorsTop10] = useState([]);
    const [clientsTop10, setclientsTop10] = useState([]);
    const [mostSoldProducts, setmostSoldProducts] = useState([]);
    const [mostBoughtProducts, setmostBoughtProducts] = useState([]);
    const [cumulativeByStatus, setcumulativeByStatus] = useState([]);

    const dateNow = new Date().toISOString().slice(0,7);
    const [startDate, setStartDate] = useState(dateNow + '-01');
    const [endDate, setendDate] = useState(dateNow + '-30');
    // console.log({startDate},{endDate});

    const getFurnitorTop10 = () => {
      axios.get(`statistics/top10-furnitors/${startDate}/${endDate}`).then(data => {
        console.log('top10furnitors', data.data);
        setfurnitorsTop10(data.data);
        setLoading(false);

      }).catch(err => console.log(err));
    }
    const getClientsTop10 = () => {
      axios.get(`statistics/top10-clients/${startDate}/${endDate}`).then(data => {
        console.log('top10clients', data.data);
        setclientsTop10(data.data);
      }).catch(err => console.log(err));
    }
    const getMostSoldProducts = () => {
      axios.get(`statistics/most-sold-products/${startDate}/${endDate}`).then(data => {
        console.log('most-sold-products', data.data);
        setmostSoldProducts(data.data);
      }).catch(err => console.log(err));
    }
    const getMostBoughtProducts = () => {
      axios.get(`statistics/most-bought-products/${startDate}/${endDate}`).then(data => {
        console.log('most-bought-products', data.data);
        setmostBoughtProducts(data.data);
      }).catch(err => console.log(err));
    }
    const getCumulativeByType = () => {
      axios.get(`statistics/cumulative/all/${startDate}/${endDate}`).then(data => {
        console.log('getCumulativeByType', data.data);
        setcumulativeByStatus(data.data);
      }).catch(err => console.log(err));
    }


const handleRefresh = (e) => {
  e.preventDefault();
    getClientsTop10();
    getCumulativeByType();
    getFurnitorTop10();
    getMostBoughtProducts();
    getMostSoldProducts();
}

      let colors = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ];

      const monthNames = ["Janar", "Shkurt", "Mars", "Pril", "Maj", "Qërshorë",
      "Korrik", "Gusht", "Shtatorë", "Tetorë", "Nëndorë", "Dhjetorë"
      ];


    const mostSoldByPrice = {
      type: 'bar',
      labels: mostSoldProducts && mostSoldProducts?.map(el => {return el.name_al}),
      datasets: [       
        {
          label: 'Produktet më të shitura sipas çmimit',
          data: mostSoldProducts.map(el => {return el.totalPriceSold}) ,
          backgroundColor : [ 'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)','rgb(54, 162, 235)', 'chocolate','rgb(153, 102, 255)','rgb(201, 203, 207)' ]
      }],
      options: {
          maintainAspectRatio: true,
          scales: { y: { beginAtZero: false}},
          legend: { labels: { fontSize: 10}}
      }
      
  }
    const mostSoldByQty = {
      type: 'bar',
      labels: mostSoldProducts && mostSoldProducts?.map(el => {return el.name_al}),
      
      datasets: [       
        {
          label: 'Produktet më të shitura sipas sasisë (KG)',
          data: mostSoldProducts.map(el => {return el.totalQtySold}) ,
          backgroundColor : [ 'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)','rgb(54, 162, 235)','chocolate', 'rgb(153, 102, 255)','rgb(201, 203, 207)' ]
      }],
      options: {
          maintainAspectRatio: true,
          scales: { y: { beginAtZero: true}},
          legend: { labels: { fontSize: 10}},
          backgroundColor: '#FFF',
          
      }
      
  }
    const mostBoughtByPrice = {
      type: 'bar',
      labels: mostBoughtProducts && mostBoughtProducts?.map(el => {return el.name_al}),
      
      datasets: [       
        {
          label: 'Produktet më të blera sipas sasise çmimit',
          data: mostBoughtProducts.map(el => {return el.totalPriceBought}) ,
          backgroundColor : ['rgb(54, 162, 235)','chocolate', 'rgb(153, 102, 255)','rgb(201, 203, 207)' , 'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)']
      }],
      options: {
          maintainAspectRatio: true,
          scales: { y: { beginAtZero: true}},
          legend: { labels: { fontSize: 10}},
          backgroundColor: '#FFF',
          
      }
      
  }
  const mostBoughtByQty = {
    type: 'bar',
    labels: mostBoughtProducts && mostBoughtProducts?.map(el => {return el.name_al}),
    
    datasets: [       
      {
        label: 'Produktet më të blera sipas sasisë (KG)',
        data: mostBoughtProducts.map(el => {return el.totalQtyBought}) ,
        backgroundColor : [ 'rgb(54, 162, 235)','chocolate', 'rgb(153, 102, 255)','rgb(201, 203, 207)' ,'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)']
    }],
    options: {
        maintainAspectRatio: true,
        scales: { y: { beginAtZero: true}},
        legend: { labels: { fontSize: 10}},
        backgroundColor: '#FFF',
        
    }
    
}
  const top10furnitorsgraph = {
    type: 'bar',
    labels: furnitorsTop10 && furnitorsTop10?.map(el => {return el.full_name}),
    
    datasets: [       
      {
        label: '10 Furnitorët më të mirë',
        data: furnitorsTop10.map(el => {return el.total}) ,
        backgroundColor : [ 'rgb(153, 102, 255)','rgb(201, 203, 207)' ,'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)','rgb(54, 162, 235)','chocolate', 'rgb(153, 102, 255)','rgb(201, 203, 207)' ]
    }],
    options: {
        maintainAspectRatio: true,
        scales: { y: { beginAtZero: true}},
        legend: { labels: { fontSize: 10}},
        backgroundColor: '#FFF',
        
    }
    
}
  const top10clientsgraph = {
    type: 'bar',
    labels: clientsTop10 && clientsTop10?.map(el => {return el.company_name}),
    
    datasets: [       
      {
        label: '10 Klientët më të mirë',
        // label: clientsTop10 && clientsTop10?.map(el => {return el.company_name}),
        data: clientsTop10.map(el => {return el.total}) ,
        backgroundColor : [ 'rgb(153, 102, 255)','rgb(201, 203, 207)' ,'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)','rgb(54, 162, 235)','chocolate', 'rgb(153, 102, 255)','rgb(201, 203, 207)' ]
    }],
    options: {
        maintainAspectRatio: true,
        scales: { y: { beginAtZero: true}},
        legend: { labels: { fontSize: 10}},
        backgroundColor: '#FFF',
        
    }
    
}
  const cumulativegraph = {
    type: 'bar',
    labels: ['Blerjet','Shitjet'],
    datasets: [       
      {
        label:  ['Të Paguara'],
        data: [cumulativeByStatus.selling_paid, cumulativeByStatus.buying_paid],
        backgroundColor : [ 'rgb(54, 162, 235)']
    },
      {
        label:  ['Të Pa Paguara'],
        data: [ cumulativeByStatus.selling_unpaid, cumulativeByStatus.buying_unpaid],
        backgroundColor : [ 'rgb(255, 159, 64)']
    }
    ,
      {
        label:  ['Total'],
        data: [ cumulativeByStatus.selling_total, cumulativeByStatus.buying_total],
        backgroundColor : [ 'rgb(255, 205, 86)']
    }
  
  ],
    options: {
        maintainAspectRatio: true,
        scales: { y: { beginAtZero: true}},
        legend: { labels: { fontSize: 10}},
        backgroundColor: '#FFF',
        
    }
    
}



  
  
  useEffect(()=> {
    getClientsTop10();
    getCumulativeByType();
    getFurnitorTop10();
    getMostBoughtProducts();
    getMostSoldProducts();
    }, []);


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='statistics' style={{left: props.menu ? '20%' : '0'}}>
        <div className='title' style={{left: props.menu ? '20%' : '0'}}>
            <h3>Statistikat</h3>
            <div className="dateShow" >
           {startDate + ' | ' + endDate }
            </div>

        </div>
        <div className="fromTo" >
          <div className="refresh"><button onClick={handleRefresh}><i className="fa fa-refresh" /></button></div>
          <div className="dates">
            <label>Nga Data</label>
            <input type={'date'} defaultValue={startDate} onChange={(e)=>setStartDate(e.target.value)}></input>
            <label>Deri ne Datën</label>
            <input type={'date'} defaultValue={endDate} onChange={(e)=>setendDate(e.target.value)}></input>
          </div>

        </div>
        <div className="statisticContainer" style={{width: props.menu ? '80%' : '100%'}}>
        
            <div className="row w-100 p-2" style={{height: '500px !important'}}>
             
           { cumulativeByStatus &&  <div className="col-12">
            <h5>Blerjet dhe Shitjet sipas statusit</h5>
            <Bar data={cumulativegraph}  height={'100px'}/>
            </div> }
           { mostSoldProducts &&  <div className="col-md-6 col-12"><Bar data={mostSoldByPrice} /></div> }
           { mostSoldProducts &&  <div className="col-md-6 col-12"><Bar data={mostSoldByQty} /></div> }
           { mostBoughtProducts &&  <div className="col-md-6 col-12"><Bar data={mostBoughtByPrice} /></div> }
           { mostBoughtProducts &&  <div className="col-md-6 col-12"><Bar data={mostBoughtByQty} /></div> }
           { clientsTop10 &&  <div className="col-md-6 col-12"><Bar data={top10clientsgraph} /></div> }
           { furnitorsTop10 &&  <div className="col-md-6 col-12"><Bar data={top10furnitorsgraph} /></div> }

            </div>
        </div>
       
    </div>
    )

}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu())
    // loadingOff: () => dispatch(loadingOff()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
// export default Statistics;