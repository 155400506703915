import axios from "../../../axios";
import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import Silueta from '../../../images/logo-only.png';

function TranslationsModal(props) {
  console.log('po hapna', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);
  const [userData ,setuserData] = useState();
  const [productData, setproductData] =useState();


const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/stock/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e stokut janë përditësuar me sukses!');
              setOpen(false);
            //   window.history.go('/admin/stock');
          }
      ).catch(err => {
        toast.error('Të dhënat e stokut nuk janë përditësuar!');
          console.log(err);
        });
      
    }

const getUserData = () => {
    axios.get('/users/all').then((el) => {
        setuserData(el.data);
        console.log('userData', el.data);
    })
}
const getProductData = () => {
    axios.get('/products/').then((el) => {
        setproductData(el.data);
        console.log('productData', el.data);
    })
}
useEffect(()=> {
    getProductData();
    getUserData();
},[])
const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
      axios.post('/stock/', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e stokut janë ruajtur me sukses!');
              setOpen(false);
          }
      ).catch(err => {
        toast.error('Të dhënat e stokut nuk janë ruajtur!');
          console.log(err);
        });
      
    }
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/stock/?id='+props?.data?.id)
    .then(data=>{
        props?.getData();
        toast.success('Të dhënat e stokut janë fshier me sukses!');
        setOpen(false);
        // window.history.go('/admin/stock');
    })
    .catch(err=> {
        toast.error('Të dhënat e stokut nuk janë fshirë!');
        console.log(err);
    })
}
const ud = Object.assign({}, data);
if (props?.data?.id) {
    ud.id = props?.data?.id;
}

const product_id = (e) => {
    const ud = Object.assign({}, data);
    ud.product_id = e.target.value
    setData(ud);
}
const qty_kg = (e) => {
    const ud = Object.assign({}, data);
    ud.qty_kg = Number(e.target.value);
    setData(ud);
}
const created_by = (e) => {
    const ud = Object.assign({}, data);
    ud.created_by = e.target.value
    setData(ud);
}




console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row pl-5'>
                <div className='col-10 pl-5'>
                    {props?.type === 'add' && <h3>Shto Stok të ri!</h3>}

           { props?.data && <h3>Stoku: {' ' +props?.data?.name_al}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-warning' onClick={()=> setEdit(!isEdit)}><i className={ isEdit ? 'fa fa-times' :'fa fa-edit'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>
            {/* <div className='row pl-5'>
          { props?.type !== 'add'  &&   <div className='col-3 pl-5'>
                    <img src={props?.data?.pic_url != null ? process.env.REACT_APP_UPLOADS + props?.data?.pic_url : Silueta }  alt='Product Image' className='product-img' />
                    <button className='change-image-btn' onClick={()=> setImageEdit(!isImageEdit)}><i className={isImageEdit == false ? 'fa fa-edit' : 'fa fa-times'} /></button>
                </div>}
                <div className='col-9'>{isImageEdit && <>
                <input type='file' onChange={pic_url} />
                <button className='btn btn-success' onClick={handleImage} disabled={image == null || image == '' ? true : false}>Ruaj Imazhin!</button>
                </>} </div>
            </div> */}
            {isdelete === false && 
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision}>
                <div className='form-group'>
                <label>Produkti:</label>  
                <select  onChange={product_id} class="form-control" placeholder='elementi'  disabled={isEdit || props?.type === 'add'? false : true}>
                    <option value={null} selected disabled>Selekto Produktin</option>
                    { productData?.map((el)=> {
                            return <option value={el.id} selected={props?.data?.product_id === el.id ? props?.data?.product_id : ''} >{el.name_al} </option>
                    })}
                </select>
                </div>
                <div className='form-group'>
                <label>Sasia: </label> 
                <input type='number' step={0.1} onChange={qty_kg} class="form-control" placeholder='Sasia (kg)' defaultValue={props?.data?.qty_kg} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>Krijuar Nga: </label> 
                <select  onChange={created_by} class="form-control" 
                     defaultValue={props?.data?.created_by} disabled={isEdit || props?.type === 'add' ? false : true}>
                         <option value={null} selected disabled>Selekto Shfrytezuesit</option>
                        {userData?.map((el)=> {

                            return <option value={el.id} selected={props?.data?.created_by === el.id ? props?.data?.created_by : ''}>{el.full_name}</option>
                        })
                         }
                </select>
                </div>
                                
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
            }
                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Shto Stokun! </button>
                }
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni stokun me id: {'  ' + props?.data?.product_id}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
        </div>
    </div>
      )
  } else return null

}

export default TranslationsModal;