import React from "react";
import './loader.scss';

const Loader = () => {
    return (
        <div className="gloader">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="data">
        <h4>I'm Loading...</h4>
        </div>
        </div>
    )
}

export default Loader;