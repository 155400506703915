import React, {useEffect, useState} from "react";
import './dashboard.scss';
import axios from '../../../axios';
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
  } from "./../../../redux/Functions/actions";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, LineElement } from 'chart.js';
import { Bar, Line, PolarArea } from 'react-chartjs-2';
ChartJS.register(
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,

  )
const Dashboard = (props) => {

    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [stockData, setstockData] = useState([]);
    const [open, setOpen] = useState(true);
    const [cumulativeData, setCumulativeData] = useState([]);
    const dateNow = new Date().toISOString().slice(0,7);
    console.log('dataNow',dateNow);
    const getData = () => {
        axios.get(`statistics/cumulative/all/${dateNow}-01/${dateNow}-30`).then(data => {
            setStats(data.data);
            setLoading(false);
            console.log('cumulative', data.data);
        }).catch(err => {
            console.log(err);
        })
    }
    const getCumulativeData = () => {
        axios.get(`statistics/cumulative/thisyear`).then(data => {
            setCumulativeData(data.data);
            setLoading(false);
            console.log('cumulative', data.data);
        }).catch(err => {
            console.log(err);
        })
    }
    
    
    const getStockData = () => {
        axios.get('/statistics/overall-stock').then(
            data => {
                console.log('stockData',data.data);

                let labels = [];
                let stock = []
                data.data.map((el,index) => {
                    stock.push(el.qty_kg);
                    labels.push(el.name_al);
                })


                setstockData({dataset: stock , labels: labels});
                //  setLoading(false);
        }
    ).catch(err => { console.log('Errori',err)});
}
const thisyearData = {
    type: 'line',
   labels: cumulativeData?.labels,
     datasets: [ cumulativeData?.selling, cumulativeData?.yearsSelling, cumulativeData?.sellingPayed,  cumulativeData?.buying, cumulativeData?.yearsBuying, cumulativeData?.buyingPayed],

} ;
const stockDatas = {
    type: 'bar',
    labels: stockData.labels,
    datasets: [       
    {
        label: 'Stoku Posedues',
        data: stockData.dataset ,
        backgroundColor : [ 'rgb(255, 99, 132)','rgb(255, 159, 64)','rgb(255, 205, 86)','rgb(75, 192, 192)','rgb(54, 162, 235)', 'rgb(153, 102, 255)','rgb(201, 203, 207)' ]
    }],
    options: {
        maintainAspectRatio: false,
        scales: { y: { beginAtZero: false}},
        legend: { labels: { fontSize: 10}}
    }
    
}
const monthNames = ["Janar", "Shkurt", "Mars", "Pril", "Maj", "Qërshorë",
"Korrik", "Gusht", "Shtatorë", "Tetorë", "Nëndorë", "Dhjetorë"
];

useEffect(()=> {
    getData();
    getStockData();
    getCumulativeData();
    // window.location.reload();
}, []);


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='dashboard' >
        <div className='title' style={{left: props.menu ? '20%' : '0'}}>
            <h3>Dashboardi</h3>
        </div>
        <div className="dashboardContainer" style={{width: props.menu ? '80%' : '100%'}}>
            <div className="row text-center p-3">
          <h4 className="text-center">  Blerjet dhe shitjet per muajin {dateNow}</h4>
            </div>
            <div className="row w-100 ">
                <div className="col-md-6 col-12 ">
                    <div className="row w-100">
                        <div className="dashboardItem bg-dark text-light ">Shitje Total <br/><h3>{stats && (stats.selling_total != null ? stats.selling_total : 0) + ' EUR'}</h3></div>
                        <div className="dashboardItem bg-success  text-light">Shitje te paguara <br/><h3>{stats && (stats.selling_paid !== null ? stats.selling_paid  : 0)+ ' EUR'}</h3></div>
                        <div className="dashboardItem bg-warning  text-light">Shitje te papaguara <br/><h3>{stats && ( (stats.selling_unpaid  !== null ? stats.selling_unpaid  : 0)* -1) + ' EUR'}</h3></div>
                    </div>
                    
                </div>
                <div className="col-md-6 col-12 ">
                    <div className="row w-100">
                    <div className="dashboardItem bg-dark text-light ">Blerjet Total <br/><h3>{stats && (stats.buying_total !== null ? stats.buying_total : 0 ) + ' EUR'}</h3></div>
                        <div className="dashboardItem bg-success  text-light">Blerjet te paguara <br/><h3>{stats && (stats.buying_paid !== null ? stats.buying_paid : 0 ) + ' EUR'}</h3></div>
                        <div className="dashboardItem bg-warning  text-light">Blerjet te papaguara <br/><h3>{stats && ( (stats.buying_unpaid !== null ? stats.buying_unpaid : 0) * -1) + ' EUR'}</h3></div>
                    </div>
                    
                </div>
                <div className="col-md-12 col-12 ">
                  <h4>Stoku Posedues</h4>
                {stockData && <Bar data={stockData && stockDatas} />}
                    
                </div>
                <div className="col-md-12 col-12 ">
                  <h4>Blerjet dhe shitjet sipas statusit dhe muajit</h4>
              {stats &&  <Line data={cumulativeData && thisyearData} />}
                </div>
            </div>
        </div>
       
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
      // loadingOff: () => dispatch(loadingOff()),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
// export default Dashboard;