import axios from "../../../axios";
import React, {useEffect, useState} from "react";
// import {usenavigate} from 'react-router';
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Silueta from '../../../images/logo-only.png';

function FurnitoretModal(props) {
//   console.log('po hapna', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);
  const [userData ,setuserData] = useState();
  const [productData, setproductData] =useState();
// const navigate = useNavigate();
const navigate = props.location;

const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/furnitors', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e furnitorit janë përditësuar me sukses!');
              setOpen(false);
            //   Link.to('/admin/bashkepuntoret');
            //   navigate.push('/admin/bashkepuntoret');
          }
      ).catch(err => {
        toast.error('Të dhënat e furnitorit nuk janë përditësuar!');
          console.log(err);
        });
      
    }


useEffect(()=> {

},[])
const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
      axios.post('/furnitors', data).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e furnitorit janë ruajtur me sukses!');
              setOpen(false);
            //   navigate.push('/admin/bashkepuntoret');
          }
      ).catch(err => {
        toast.error('Të dhënat e anëtarit nuk janë ruajtur!');
          console.log(err);
        });
      
    }
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/furnitors/?id='+props?.data?.id)
    .then(data=>{
        props?.getData();
        toast.success('Të dhënat e furnitorit janë fshier me sukses!');
        setOpen(false);
        // navigate.push('/admin/bashkepuntoret');
    })
    .catch(err=> {
        toast.error('Të dhënat e furnitorit nuk janë fshirë!');
        console.log(err);
    })
}

const full_name = (e) => {
    const ud = Object.assign({}, data);
    ud.full_name = e.target.value
    setData(ud);
}
const phone = (e) => {
    const ud = Object.assign({}, data);
    ud.phone = e.target.value
    setData(ud);
}
const id_nr = (e) => {
    const ud = Object.assign({}, data);
    ud.id_nr = e.target.value
    setData(ud);
}




console.log('dataObject', data);
  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5'>
            <div className='row pl-5'>
                <div className='col-10 pl-5'>
                    {props?.type === 'add' && <h3>Shto furnitorë të ri!</h3>}

           { props?.data && <h3>Produkti: {' ' +props?.data?.full_name}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-warning' onClick={()=> setEdit(!isEdit)}><i className={ isEdit ? 'fa fa-times' :'fa fa-edit'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>
            {/* <div className='row pl-5'>
          { props?.type !== 'add'  &&   <div className='col-3 pl-5'>
                    <img src={props?.data?.pic_url != null ? process.env.REACT_APP_UPLOADS + props?.data?.pic_url : Silueta }  alt='Product Image' className='product-img' />
                    <button className='change-image-btn' onClick={()=> setImageEdit(!isImageEdit)}><i className={isImageEdit == false ? 'fa fa-edit' : 'fa fa-times'} /></button>
                </div>}
                <div className='col-9'>{isImageEdit && <>
                <input type='file' onChange={pic_url} />
                <button className='btn btn-success' onClick={handleImage} disabled={image == null || image == '' ? true : false}>Ruaj Imazhin!</button>
                </>} </div>
            </div> */}
            {isdelete === false && 
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision}>
                <div className='form-group'>
                <label>Emri:</label>  
                <input  type='text' onChange={full_name} class="form-control" placeholder='Filan Fisteku' defaultValue={props?.data?.full_name} disabled={isEdit || props?.type === 'add'? false : true} />
                </div>
                <div className='form-group'>
                <label>Nr. Telefonit: </label> 
                <input type='text' onChange={phone} class="form-control" placeholder='044 444 444' defaultValue={props?.data?.phone} disabled={isEdit || props?.type === 'add'? false : true}/>
                </div>
                <div className='form-group'>
                <label>Nr. Leternjoftimit: </label> 
                <input type='text' onChange={id_nr} class="form-control"  placeholder='123123123'
                     defaultValue={props?.data?.id_nr} disabled={isEdit || props?.type === 'add'? false : true} />
                      
                </div>
                                
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
            }
                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Shto Furnitorin! </button>
                }
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni furnitorin me emërin: {'  ' + props?.data?.full_name}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
        </div>
    </div>
      )
  } else return null

}

export default FurnitoretModal;