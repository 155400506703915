import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  ReactPDF,
  Svg,
} from "@react-pdf/renderer";

import Logo from "./../../../images/gplus_logo-05.png";
// import QrCode from './../../../images/qrcode.png';
// import ExmpleLogo from './../../../images/exmpleLogo.png';

const POSTER_PATH = "https://image.tmdb.org/t/p/w154";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    padding: 10,
  },

  tableContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: "white",
    width: "80%",
    margin: "0 auto",
    borderRadius: "4px",
    border: "1px solid #DADADA",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, .08)",
  },

  tableRow: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },

  heading: {
    backgroundColor: "#ececec",
    color: "#3e3e3e",
    fontWeight: "bold",
  },

  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.15s ease-in-out",
  },

  rowNoBorder: {
    flexDirection: "row",
  },
  invoiceHeadRow: {
    flexDirection: "row",
    fontWeight: 900,
    backgroundColor: "#0b9444",
    color: "#FFFFFF",
  },
  rowNoBorderCapital: {
    flexDirection: "row",
    // textTransform: 'uppercase',
  },
  row: {
    flexDirection: "row",
    borderTop: "1px solid #CBCBCB",
    borderLeft: "1px solid #CBCBCB",
    borderRight: "1px solid #CBCBCB",
    padding: 2,
    // borderBottom: '1px solid black',
  },
  colPatient: {
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    // borderRight: '1px solid black',
    padding: 5,
    textAlign: "right",
  },
  col: {
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    // borderRight: '1px solid black',
    padding: 7,
  },
  invoicecol: {
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    borderRight: "1px solid #CBCBCB",
    padding: 7,
  },
  invoicecollast: {
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    // borderRight: '1px solid black',
    padding: 7,
  },
  invoicecol2: {
    flexDirection: "column",
    flex: 2,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    borderRight: "1px solid #CBCBCB",
    padding: 7,
  },
  invoicecol5: {
    flexDirection: "column",
    flex: 5,
    fontSize: 9,
    // border: '1px solid #CBCBCB',
    borderRight: "1px solid #CBCBCB",
    padding: 7,
  },
  col2: {
    flexDirection: "column",
    flex: 2,
    fontSize: 9,

    // borderRight: '1px solid black',
    padding: 7,
  },
  col3: {
    flexDirection: "column",
    flex: 3,
    fontSize: 9,

    // borderRight: '1px solid black',
    padding: 7,
  },
  topRow: {
    flexDirection: "row",
    borderLeft: "1px solid #CBCBCB",
    borderRight: "1px solid #CBCBCB",
    borderTop: "1px solid #CBCBCB",
  },
  bottomRow: {
    flexDirection: "row",
    borderLeft: "1px solid #CBCBCB",
    borderRight: "1px solid #CBCBCB",
    borderBottom: "1px solid #CBCBCB",
    borderTop: "1px solid #CBCBCB",
    padding: 2,
  },
  productName: {
    fontSize: 9,
    flex: 1,
  },

  section: {
    display: "flex",
    flexWrap: "wrap",
  },

  lineBbreak: {
    flexBasis: "100%",
    // width: '100%',
    height: "10px",
    overflow: "hidden",
  },
  separation: {
    backgroundColor: "#0b9444",
    width: "100%",
    paddingLeft: 22,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 22,
    fontWeight: 700,
    fontSize: 10,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
});

const logoSource = {
  uri: Logo,
  method: "GET",
  headers: { "Access-Control-Allow-Origin": "*" },
  body: "",
};
// const qrCodeSource = { uri: QrCode, method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' }, body: '' }
// const exampleLogo = { uri: ExmpleLogo, method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' }, body: '' }

export function PdfDocument(props) {
  // console.log("pdf props", props);
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.rowNoBorder}>
          {/* <Svg viewBox={Logo}  width={'120px'}/> */}
          <View style={styles.col}>
            <Image source={logoSource} style={{ width: "120px" }} />
          </View>
          {/* <View style={styles.col}>
                        <Text style={{ textAlign: "right", flex: 1, paddingTop: 10 , fontSize: 22, color: '#0b9444'}}> Invoice</Text>
                    </View> */}
          <View style={styles.col2}>
            <View style={styles.rowNoBorder}>
              <Text
                style={{
                  textAlign: "right",
                  flex: 1,
                  paddingTop: 10,
                  fontSize: 22,
                  color: "#0b9444",
                }}
              >
                {" "}
                Invoice
              </Text>
            </View>
            <View style={{ height: "20px" }}></View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text>ID e Biznesit: </Text>
              </View>
              <View style={styles.col3}>
                <Text>{props.selectedFurnitor?.bussines_id}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text>Emri i Biznesit :</Text>
              </View>
              <View style={styles.col3}>
                <Text>{props?.selectedFurnitor?.company_name}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text>Adresa: </Text>
              </View>
              <View style={styles.col3}>
                <Text>
                  {props?.selectedFurnitor?.address +
                    ", " +
                    props?.selectedFurnitor?.city +
                    ", " +
                    props?.selectedFurnitor?.state}
                </Text>
              </View>
              \{" "}
            </View>

            <View style={styles.bottomRow}>
              <View style={styles.col}>
                <Text>Informatat Bankare</Text>
              </View>
              <View style={styles.col3}>
                <Text>
                  {"Logaria :" +
                    props?.selectedFurnitor?.bank_acc +
                    " | Kodi Bankar: " +
                    props?.selectedFurnitor?.bank_code +
                    " | SWIFT : " +
                    props?.selectedFurnitor?.swift_code}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* <View style={styles.topRow}>
                    <View style={styles.col2}><Text>Document</Text></View>
                    <View style={styles.col}><Text>Identification</Text></View>
                    <View style={styles.col}><Text>{props.billData.bill_numer}</Text></View>
                    <View style={styles.col2}><Text></Text></View>
                    <View style={styles.col2}><Text></Text></View>
                </View> */}
        {/* <View style={styles.row}>
                    <View style={styles.col2}>
                        <View style={styles.page}>
                            <View style={styles.col}><Text></Text></View>
                            <View style={styles.col}><Text>Rechungsteller</Text></View>
                            <View style={styles.col}><Text>Leistungserbringer</Text></View>
                            <View style={styles.col}><Text></Text></View>
                        </View>
                    </View>
                    <View style={styles.col}>
                        <View style={styles.page}>
                            <View style={styles.col}><Text>GLN</Text></View>
                            <View style={styles.col}><Text>ZSR</Text></View>
                            <View style={styles.col}><Text>RCC</Text></View>
                            <View style={styles.col}><Text>ZSR</Text></View>
                        </View>
                    </View>
                    <View style={styles.col}>
                        <View style={styles.page}>
                            <View style={styles.col}><Text>{props.clinicData.gln_nr}</Text></View>
                            <View style={styles.col}><Text>{props.clinicData.gln_nr}</Text></View>
                            <View style={styles.col}><Text>{props.clinicData.rcc_nr}</Text></View>
                            <View style={styles.col}><Text></Text></View>
                        </View>
                    </View>
                    <View style={styles.col2}>
                        <View style={styles.page}>
                            <View style={styles.col}><Text>{props.clinicData.address}</Text></View>
                            <View style={styles.col}><Text>{props.clinicData.name}</Text></View>
                            <View style={styles.col}><Text>{props.clinicData.author}</Text></View>
                        </View>
                    </View>
                    <View style={styles.col2}>
                        <View style={styles.page}>
                            <View style={styles.col}><Text>Tel.{props.clinicData.phone}</Text></View>
                            <View style={styles.col}><Text>Fax.</Text></View>
                            <View style={styles.col}><Text>E-Mail:{props.clinicData.email}</Text></View>
                        </View>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col}><Text>Patient</Text></View>
                    <View style={styles.colPatient}>
                        <Text style={styles.rowNoBorder}>Name</Text>
                        <Text style={styles.rowNoBorder}>Vorname</Text>
                        <Text style={styles.rowNoBorder}>Strasse</Text>
                        <Text style={styles.rowNoBorder}>PLZ</Text>
                        <Text style={styles.rowNoBorder}>Ort</Text>
                        <Text style={styles.rowNoBorder}>Gubertsdatum</Text>
                        <Text style={styles.rowNoBorder}>Geschlecht</Text>
                        <Text style={styles.rowNoBorder}>Falldatum</Text>
                        <Text style={styles.rowNoBorder}>Fall-Nr.</Text>
                        <Text style={styles.rowNoBorder}>AHV-nr</Text>
                        <Text style={styles.rowNoBorder}>Veka-N.r</Text>
                        <Text style={styles.rowNoBorder}>Versicherten0NR</Text>
                        <Text style={styles.rowNoBorder}>Kanton</Text>
                        <Text style={styles.rowNoBorder}>Kopie</Text>
                        <Text style={styles.rowNoBorder}>Vergutungsart</Text>
                        <Text style={styles.rowNoBorder}>Gesetz </Text>
                        <Text style={styles.rowNoBorder}>Vertrags</Text>
                        <Text style={styles.rowNoBorder}>Behjalundg</Text>
                        <Text style={styles.rowNoBorder}>Bertriebe</Text>
                        <Text style={styles.rowNoBorder}>Rolle</Text>
                    </View>
                    <View style={styles.colPatient}>
                        <Text style={styles.rowNoBorder}>{props?.patientData?.patientName}</Text>
                        <Text style={styles.rowNoBorder}>{props?.patientData?.patientSurName}</Text>
                        <Text style={styles.rowNoBorder}>{props?.patientData?.patientAddress}</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>{}</Text>
                        <Text style={styles.rowNoBorder}>{(props?.patientData?.gender == 1 && 'M') || (props?.patientData?.gender == 2 && 'F') || (props?.patientData?.gender == 3 && 'O')}</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>Ja</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>- </Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                        <Text style={styles.rowNoBorder}>-</Text>
                    </View>


                    <View style={styles.col3}>
                        <View style={styles.rowNoBorder}>
                            <View style={styles.blueBox}>
                                <Text style={styles.rowNoBorderCapital}> {(props?.patientData?.gender == 1 && 'Mr.') || (props?.patientData?.gender == 2 && 'Mrs.')} </Text>
                                <Text style={styles.rowNoBorderCapital}> {props?.patientData?.patientName + ' ' + props?.patientData?.patientSurName} </Text>
                                <Text style={styles.rowNoBorderCapital}> {props?.patientData?.patientAddress} </Text>
                                <Text style={styles.rowNoBorderCapital}> - </Text>
                            </View>
                        </View>
                        <View style={styles.rowNoBorder}>
              \
                        </View>
                    </View>
                </View>
            */}

        <View style={{ height: "20px" }}></View>
        <View style={styles.section}>
          <View style={styles.invoiceHeadRow}>
            <View style={styles.invoicecol2}>
              <Text>Date</Text>
            </View>
            <View style={styles.invoicecol5}>
              <Text>Item</Text>
            </View>
            <View style={styles.invoicecol}>
              <Text>QTY</Text>
            </View>
            <View style={styles.invoicecol}>
              <Text>Price/QTY</Text>
            </View>
            <View style={styles.invoicecollast}>
              <Text>Total</Text>
            </View>
          </View>
          {props.billItems
            ? props.billItems.map((a, index) => {
                return (
                  <View key={index}>
                    <View style={styles.rowNoBorder}>
                      <View style={styles.invoicecol2}>
                        <Text>{props?.billData?.created_at?.slice(0, 10)}</Text>
                      </View>
                      {/* <View style={styles.invoicecol}><Text>{a.tarif}</Text></View> */}
                      <View style={styles.invoicecol5}>
                        <Text>{a.name_al}</Text>
                      </View>
                      <View style={styles.invoicecol}>
                        <Text>{a.qty_inserted}</Text>
                      </View>
                      <View style={styles.invoicecol}>
                        <Text>{a.price_per_qty + " EUR"}</Text>
                      </View>
                      {/* <View style={styles.invoicecol}><Text>{a.vat}</Text></View> */}
                      {/* <View style={styles.invoicecol}><Text>MWST</Text></View> */}
                      <View style={styles.invoicecollast}>
                        <Text>{a.total_inserted + " EUR"}</Text>
                      </View>
                    </View>
                    <View style={styles.separation}>
                      <Text>{a.name}</Text>
                    </View>
                  </View>
                );
              })
            : ""}
        </View>
        <View style={styles.rowNoBorder}>
          {/* <View style={styles.col}></View> */}
          <View style={styles.col3}></View>
          <View style={styles.col3}></View>
          <View style={styles.col3}></View>
          <View style={styles.col3}>
            <View style={styles.rowNoBorder}>
              <View style={styles.separation}>
                <View style={styles.rowNoBorder}>
                  {/* <View style={styles.col1}><Text>Total</Text></View> */}
                  <View style={styles.col}>
                    <Text>{"Total: " + props?.billData?.total + " EUR"}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

// ReactPDF.render(<PdfDocument />, `${__dirname}/example.pdf`);
