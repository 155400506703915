import axios from "../../../axios";
import React, {useEffect, useState} from "react";
// import {usenavigate} from 'react-router';
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Silueta from '../../../images/logo-only.png';
import Select from 'react-select';
import { getNodeText } from "@testing-library/react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./PdfDocument";


function ShitjetModal(props) {
  console.log('blerjetProps', props);
  const [isOpen,setOpen ] = useState(true);
  const [ isEdit, setEdit] = useState(false);
  const [data, setData] = useState(props.data);
  const [isdelete, setDelete] = useState(false);
  const [selectedOption ,setselectedOption] = useState(null);
  const [selectOptions, setselectOptions] =useState([]);
  const [selectedFurnitor, setSelectedFurnitor]= useState(null);
  const [products, setProducts] = useState([]);
  const [rerender, setReRender] = useState(0);

  const [toBuy,setToBuy] =useState([]);

const handleSelectOptionsState = () => {
    let map = props?.furnitorData?.map((el, index) => {
        let myobj = { value: el.id, label: el.company_name };
        setselectOptions(selectOptions => [...selectOptions, myobj]);
    });
    console.log('mapStart',map);
}


const getItems = () => {
    axios.get('/sellings/items/id?id='+ props.data.id).then(data => {
        console.log('items', data.data);
        setToBuy(data.data);
    }).catch(err => {
        console.log(err);
    })
}
console.log('toBuy', toBuy);

useEffect(()=>{
    if(props?.products) {
        setProducts(props.products);
    }
    if(props?.furnitorData) {
        handleSelectOptionsState()
    }
    if(props?.data) {
        handleSelectChanges({
            value: props.data.furnitor_id,
            label: props.data.furnitor_name
        });
    if(props.type=== "edit") {
        getItems();
    }
    }
},[props]);

// refresh products if Edit
useEffect(()=>{

if(props.data && props.type === 'edit') {
    handleSelectChanges({value: props.data.customer_id});
}
    
},[props.data])

 

  const handleSelectChanges = async (selectedOption) => {
    setselectedOption(selectedOption);
    let selectedOnFurnitor = await props?.furnitorData?.filter(data => data.id === selectedOption.value)[0];
    setSelectedFurnitor(selectedOnFurnitor);
    // console.log(`Option selected:`, selectedOption);
    console.log('selectedFurnitor', selectedOnFurnitor);
  }

  console.log('selectedFurnitor', selectedFurnitor);
const handelEditSubmmision = (e) => {
      e.preventDefault();
      setEdit(false);
      axios.put('/customers', data).then(
          data=> {
              console.log('goingData', data);
              props.getData();
              toast.success('Të dhënat e furnitorit janë përditësuar me sukses!');
              setOpen(false);
            //   Link.to('/admin/bashkepuntoret');
            //   navigate.push('/admin/bashkepuntoret');
          }
      ).catch(err => {
        toast.error('Të dhënat e furnitorit nuk janë përditësuar!');
          console.log(err);
        });
      
    }


useEffect(()=> {

},[])
const handelAddSubmmision = (e) => {
      e.preventDefault();
    //   setEdit(false);
    let today = new Date().toISOString();
    let objToDb = {
        customer_id: selectedFurnitor.id,
        company_name: selectedFurnitor.company_name,
        bussines_id: selectedFurnitor.bussines_id,
        total: toBuy.reduce((a, b) => +a + +b.total_inserted, 0)?.toFixed(2),
        payment_status: "Inicuar",
        payment_method: "Chash",
        created_by: props?.redux?.user_id,
        payment_date: today?.slice(0,10),
        items: toBuy
    }
    console.log('beforDBSave', objToDb);
      axios.post('/sellings/add', objToDb ).then(
          data=> {
              console.log('goingData', data);
              props?.getData();
              toast.success('Të dhënat e shitjes janë ruajtur me sukses!');
              setOpen(false);
            //   navigate.push('/admin/bashkepuntoret');
          }
      ).catch(err => {
        toast.error('Të dhënat e shitjes nuk janë ruajtur!');
          console.log(err);
        });
      
}
  
const handleDelete = (e)=> {
    e.preventDefault();
    axios.delete('/sellings/?id='+props?.data?.id)
    .then(data=>{
        props?.getData();
        toast.success('Të dhënat e blerjes janë fshier me sukses!');
        setOpen(false);
        // navigate.push('/admin/bashkepuntoret');
    })
    .catch(err=> {
        toast.error('Të dhënat e blerjes nuk janë fshirë!');
        console.log(err);
    })
}

const full_name = (e) => {
    const ud = Object.assign({}, data);
    ud.full_name = e.target.value
    setData(ud);
}
const phone = (e) => {
    const ud = Object.assign({}, data);
    ud.phone = e.target.value
    setData(ud);
}
const id_nr = (e) => {
    const ud = Object.assign({}, data);
    ud.id_nr = e.target.value
    setData(ud);
}



const handleQty = (e, el, index) => {
    let value = Number(e.target.value);
    console.log('changeLog',value, el, index);
    let currentState = toBuy.length >= 0 && toBuy;
    console.log('test', currentState[index]);
    let assign = Object.assign({}, currentState);
    assign[index].qty_inserted = value;
    // assign[index].amount_inserted = value * currentState[index].buying_price;
    assign[index].total_inserted = (assign[index].amount_inserted ? assign[index].amount_inserted : currentState[index].selling_price) * value ;
//    console.log('total',assign[index].total_inserted);
    setToBuy(currentState);
    setReRender(assign[index].total_inserted);
}
const handlePrice = (e, el, index) => {
    let value = e.target.value;
    console.log('changeLog',value, el, index);
    let currentState = toBuy.length >= 0 && toBuy;
    console.log('test', currentState[index]);
    let assign = Object.assign({}, currentState);
    assign[index].amount_inserted = value;
    // assign[index].amount_inserted = value * currentState[index].buying_price;
    assign[index].total_inserted = currentState[index].qty_inserted * value ;
    setToBuy(currentState);
    setReRender(value);
}

const handleToBuy = (obj, index) => {
   let myEl = { 
    buying_price: obj.buying_price,
    capacity: obj.capacity,
    desc_al: obj.desc_al,
    desc_en: obj.desc_en,
    id: obj.id,
    name_al: obj.name_al,
    name_en: obj.name_en,
    pic_url: obj.pic_url,
    selling_price: obj.selling_price,
    updated_at: obj.updated_at,
    index: index,
    qty_inserted: 1,
    amount_inserted: Number(obj.selling_price),
    total_inserted: (1 * Number(obj.selling_price)),
}
    setToBuy(toBuy => [...toBuy, myEl]);
    let prod = products?.filter( el => { return el.id !== myEl.id  });
    console.log('removetItem' ,prod);
    setProducts(prod);
    
}
console.log('toBuy', toBuy);


const handleRemoveToBuy = (obj, index) => {

    let myEl = { 
        buying_price: obj.buying_price,
        capacity: obj.capacity,
        desc_al: obj.desc_al,
        desc_en: obj.desc_en,
        id: obj.id,
        name_al: obj.name_al,
        name_en: obj.name_en,
        pic_url: obj.pic_url,
        selling_price: obj.selling_price,
        qty_inserted: 1,
        amount_inserted: Number(obj.buying_price),
        total_inserted: (1 * Number(obj.buying_price)),
        updated_at: obj.updated_at,
        index: index
    }
        setProducts(toBuy => [...toBuy, myEl]);
        let prod = toBuy?.filter( el => { return el.id !== myEl.id  });
        console.log('removetItem' ,prod);
        setToBuy(prod);
        setReRender(index);
        
}

const [isDragging , setDragging] = useState(false);
const [pdf, setPdf] = useState(false);
// console.log('isDragging', isDragging);
// console.log('selectedFurnitor', selectedFurnitor);
const [pay, setPay] = useState(false);
const [paymentStatus, setPaymentStatus] = useState({
    payment_method: 'Cash',
    payment_status: 'Paguar',
    payment_date: new Date().toISOString().slice(0,10)
});

console.log('paymentStatus',paymentStatus);
const handlePymentMethod = (e) => {
    e.preventDefault();
    let assign = Object.assign({}, paymentStatus);  
     assign.payment_method = e.target.value;
    setPaymentStatus(assign);
}
const handlePymentStatus = (e) => {
    e.preventDefault();
    let assign = Object.assign({}, paymentStatus);  
     assign.payment_status = e.target.value;
    setPaymentStatus(assign);
}
const handlePymentDate = (e) => {
    e.preventDefault();
    let assign = Object.assign({}, paymentStatus);  
     assign.payment_date = e.target.value;
    setPaymentStatus(assign);
}
const savePaymentStatus = () => {
    axios.put(`sellings/payment/${props?.data?.id}`,paymentStatus).then(data => {
        console.log(data.data);
        setPay(false);
        props.getData();
    }).catch(err => {
        console.log(err);
    })
}




  if (isOpen) {
      return(
        <div className='mymodal' >
            <button className='closeModal bg-danger' onClick={()=>setOpen(false)}><i className='fa fa-times' /></button>
        <div className='mymodal-wrapper p-5' style={{width: '80%', height: '90%'}}>
            <div className='row pl-5'>
                <div className='col-10 pl-5'>
                    {props?.type === 'add' && <h3>Shto Shitje të re!</h3>}

           { props?.data && <h3>Klienti: {' ' + (selectedFurnitor != null ? selectedFurnitor.company_name : 'Selekto Klientin')}</h3>}
                </div>
                { props?.data &&    <div className='col-2'>
                    <button className='editModalButton bg-success' onClick={()=> setPdf(!pdf)}><i className={ pdf ? 'fa fa-times' :'fa fa-file-pdf-o'} /></button>
                    <button className='deleteModalButton bg-danger' onClick={()=>setDelete(!isdelete)}><i className={isdelete ? 'fa fa-times':'fa fa-trash'} /></button>
                </div>
            }
            </div>
            
            {isdelete === false && pdf !== true &&
            <form onSubmit={props?.type === 'add' ? handelAddSubmmision : handelEditSubmmision} style={{width: '85%'}}>
                <div className='form-group'>
              {props?.type === 'add' && <> 
               <label>Klienti:</label>  
                <Select 
                      value={selectedOption}
                      onChange={handleSelectChanges}
                      options={selectOptions.length > 0 && selectOptions}
                      isDisabled={isEdit || props?.type === 'add'? false : true}
                      
                />
                </>}
                </div>
                {selectedFurnitor != null && 
                    <div className="form-group" >
                        <p><b>Emri:</b> {selectedFurnitor.company_name}   |  <b>ID:</b> {selectedFurnitor.bussines_id}   |  <b>Adresa:</b> {selectedFurnitor.address } </p>
                       
                
                    </div>}
                    {props?.type === 'edit' && props?.data?.payment_status === 'Inicuar' && <div className="row">
                            <span className="rounded-pill bg-danger text-light p-2 mb-2">Statusi i Pageses: {props?.data?.payment_status}</span>
                            <button className=" btn btn-success rounded-pill pt-1 mb-2 ml-2 pl-4 pr-4"
                            onClick={pay ===false ? (e)=>{
                                e.preventDefault();
                                setPay(true);
                            } : savePaymentStatus}
                            >{pay === false ? 'Paguaj' : 'Ruaj Ndryshimet'}</button>
                        </div>}
                        {pay === true && <div className="row mb-3 mt-2" style={{width: '40%'}}>
                            <div className="col-6 text-right">Data</div>
                            <div className="col-6 text-right"><input className="w-100" type='date' onChange={handlePymentDate}></input></div>
                            <div className="col-6 text-right">Menyra e Pageses</div>
                            <div className="col-6 text-right"><select className="w-100" onChange={handlePymentMethod}>
                                <option value={null} selected>Selekto</option>
                                <option value={'Cash'}>Cash</option>
                                <option value={'Bank'}>Bank</option>
                                </select></div>
                            <div className="col-6 text-right">Statusi</div>
                            <div className="col-6 text-right"><select className="w-100" onChange={handlePymentStatus}>
                            <option value={null} selected>Selekto</option>
                                <option value={'Inicuar'}>Inicuar</option>
                                <option value={'Paguar'}>Paguar</option>
                                </select></div>
                        </div> }
                    {props?.type === 'edit' && props?.data?.payment_status === 'Paguar' && <div className="row">
                            <span className="rounded-pill bg-success text-light p-2 mb-2">Statusi i Pageses: {props?.data?.payment_status}</span>
                        </div>}
                    <div className="row p-0">
                        <div className="col-4 p-0">
                        <div className="productsToSelect">
                            <h5>Selekto Produktin</h5>
                            {products && products?.map((el,index)=>{
                                return(
                                <div className="selectProductItemn" draggable={ props?.type === 'add'? true : false } onDragStart={()=>{setDragging(true)}} onDragEnd={()=>{
                                    handleToBuy(el,index);
                                    setDragging(false);
                                    }}
                                    >
                                    {el.name_al}
                                
                                </div>)
                            })}
                         </div>
                        </div>
                        <div className="col-8 p-0">
                        <div className="dropItemsHolder" style={{borderStyle: isDragging ? 'dashed' : 'solid'}}>
                            <h5>Produktet e Shitura</h5> <h6>Totali { props.type === 'add' ?  (toBuy.reduce((a, b) => +a + +b.total_inserted, 0)?.toFixed(2) + " EUR") : (props.data.total + " EUR")}</h6>
                            <div className="dropItemsWrapper">
                            <div className="row">
                                        <div className="col-5">Emri</div>
                                        <div className="col-2">Sasia</div>
                                        <div className="col-2">Cmimi (per/kg)</div>
                                        <div className="col-2">Totali</div>
                                        <div className="col-1"></div>
                                    </div>
                                    {(toBuy?.length === 0 && !isDragging) && <p className="text-center pt-5">{'<---'} Selekto produktin ne anen e majt dhe barte deri ketu..</p>}
                                    {(toBuy?.length === 0 && isDragging) && <p className="text-center pt-5">...ketu...</p>}
                            {(rerender != null || rerender != rerender) && toBuy?.map((el,index)=>{
                                return <div className="dropItem">
                                    <div className="row">
                                        <div className="col-5">{ el.name_al }</div>
                                        <div className="col-2"><input type={props?.type === 'add' ? 'number' : 'text'} step={'0.1'}
                                        defaultValue={props.type === "add" ? 1 : el.qty_inserted}
                                        disabled={ props.type === 'add' ? false : true}
                                        onChange={(e) =>{ 
                                            e.preventDefault();
                                            handleQty(e,el,index);
                                            }}
                                        ></input></div>
                                        <div className="col-2"><input type={props?.type === 'add' ? 'number' : 'text'} step={'0.1'}  
                                        defaultValue={  props.type === 'edit' ? Number(el.price_per_qty) : Number(el.selling_price) }
                                        disabled={ props?.type === 'add' ? false : true}
                                          onChange={(e) =>{ 
                                            e.preventDefault();
                                            handlePrice(e,el,index);
                                            }}
                                        ></input></div>
                                        <div className="col-2">{props?.type === 'add' ? el?.total_inserted?.toFixed(2) : el?.total_inserted}</div>
                                        <div className="col-1">
                                           {props.type === 'add' && <button onClick={(e) =>{ 
                                                e.preventDefault();
                                                handleRemoveToBuy(el,index);
                                                }}
                                            ><i className="fa fa-trash" /></button>}
                                            
                                            </div>
                                    </div>
                                    
                                    
                                </div>
                            })}
                            </div>
                {isEdit && 
                <button type='submit' className='btn btn-success'> Ndrysho të dhënat! </button>
                 }

                {props?.type === 'add' && 
                
                    <button type='submit' className='btn btn-success'> Regjistro Shitjen! </button>
                }
                         </div>

                        </div>
                    </div>
                    
                
                                
            </form>
            }
            {isdelete && <>
                <div className='deleteMessage text-center p-5'>
                    <h3>A jeni i sigurtë se dëshironi ta fshijni faturne  me ID: {'  ' + props?.data?.id}</h3>
                    <button className='btn btn-danger mt-5' onClick={handleDelete}>Fshijë</button>
                </div>
            </>}
            { props.type === 'edit' && toBuy && props.furnitorData && selectedFurnitor && pdf === true && !isdelete &&
            <PDFViewer style={{ width: "100%", height: "100%" }}>
                    <PdfDocument
                      billItems={toBuy}
                      selectedFurnitor={selectedFurnitor}
                    //   customerData={patient}
                      billData={props.data}
                    />
                  </PDFViewer>
}
        </div>
    </div>
      )
  } else return null

}

export default ShitjetModal;