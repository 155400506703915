import React, {useEffect, useState} from "react";
import './translations.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import TranslationsModal from "./translationsModal";
import { connect } from "react-redux";
// import {
//    setUserId
//   } from "./../../../redux/Functions/actions";

const Translations = (props) => {

    const [loading, setLoading] = useState(true);
    const [ productdata, setproductData] = useState([]);
    const  [ openModal , setOpenModal] = useState(false);

 const getData = () => {
     axios.get('/translations').then(
         data => {
             console.log('translations',data.data);
             setproductData(data.data);
             setLoading(false);
         }
     ).catch(err => { 
         console.log('Errori',err);
        });
 }

    useEffect(()=>{

        getData();
    },[]);
const handleModal = (data, fn) => {
    // e.preventDefault();
    render(<TranslationsModal data={data} getData={fn}/>)
}
const handleAddModal = (data, fn) => {
    // e.preventDefault();
    render(<TranslationsModal data={null} getData={fn} type='add'/>)
}


if (loading === true ) {
    return <div>it is Loading</div>
} else
return (

    <div className='translations'>
        <div className='title' style={{left: props?.menu ? '20%' : '0'}}>
            <h3>Përkthimet</h3>
        </div>
        <div className='listimi w-100'>
        <div className='list-item-head row' style={{left: props?.menu ? '22%' : '2%'}}> 
            <div className='col-4'>Elementi</div>
            <div className='col-4'>Shqip</div>
            <div className='col-3'>English</div>

            <div className='col-1'>
                {/* <button className='refreshButton' onClick={()=>getData()}><i className='fa fa-refresh' /></button> */}
                </div>
        </div>
        <div className="tableContainer" style={{left: props?.menu ? '22%' : '2%'}}>
        {productdata.length === 0 && <div className='row text-center'>Ska Shenime</div>}
            {productdata && productdata?.map((el, index)=> {
                return (
                <div className='list-item row' key={index}>
                    <div className='col-4'>{el.key}</div>
                    <div className='col-4'>{el.al.slice(0,150) + ( el.al.length > 150 ? '...' : '')}</div>
                    <div className='col-3'>{el.en.slice(0,150) + ( el.en.length > 150 ? '...' : '')}</div>
                    <div className='col-1'><button className='editButton' onClick={()=> handleModal(el,getData)}><i className='fa fa-eye' /></button></div>
                </div>
                )
            })}
        </div>
        </div>
        <button className='addButton' onClick={()=>handleAddModal(null, getData)} >+</button>
    </div>
    )

}
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading ,
      loggedIn: state.data.loggedIn,
      auth: state.data.auth,
      reduxData: state.data,
      menu: state.data.menu
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    //   setUserId: (id) => dispatch(setUserId(id)),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Translations);
// export default Translations;