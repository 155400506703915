import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import './App.scss';
import { render } from "react-dom";
import Menu from './components/menu/menu';
import AdminMenu from './components/menu/adminmenu';
import { useEffect } from 'react';
import Login from './components/login/login';
import HomePage from './components/homepage/homepage';
import Users from './components/admin/users/users';
import Products from './components/admin/products/products';
import Members from './components/admin/members/members';
import Partners from './components/admin/partners/partners';
import Translations from './components/admin/translations/translations';
import Dashboard from './components/admin/dashboard/dashboard';
import Auth from './Auth';
import Stock from './components/admin/stock/stock';
import MobileMenu from './components/menu/mobilemenu';
import Bashkpuntoret from "./components/admin/bashkpuntoret/bashkpuntoret";
import Blerjet from "./components/admin/blerjet/blerjet";
import Shitjet from "./components/admin/shitjet/shitjet";
import Statistics from "./components/admin/statistics/statistics";
import { connect } from "react-redux";
import {
   
  } from "./redux/Functions/actions";

  
const App = (props) => {



  useEffect(()=> {
    window.localStorage.setItem('lang', 'en');
  },[])
  return (
    <div className="App">
      <Router>

       {props.loggedIn == true ? <AdminMenu /> : <><Menu /> <MobileMenu /></>  }
    
          
        <Routes>
          <Route exact path="/" element={props.loggedIn ? <Dashboard /> :<HomePage />}/>
          <Route exact path="/login" element={props.loggedIn ? <Dashboard/> :<Login />}/>
          { props.loggedIn === true ? <>
          <Route exact path="/admin/dashboard" element={<Dashboard />}/>
          <Route exact path="/admin/users" element={<Users />}/>
          <Route exact path="/admin/products" element={<Products />}/>
          <Route exact path="/admin/members" element={<Members />}/>
          <Route exact path="/admin/partners" element={<Partners />}/>
          <Route exact path="/admin/translations" element={<Translations />}/>
          <Route exact path="/admin/stock" element={<Stock />}/>
          <Route exact path="/admin/clients-furnitors" element={<Bashkpuntoret />}/>
          <Route exact path="/admin/purchases" element={<Blerjet />}/>
          <Route exact path="/admin/sales" element={<Shitjet />}/>
          <Route exact path="/admin/statistics" element={<Statistics />}/>
          </>
          : <Route exact path="/*" element={<HomePage />} />

}
        </Routes>
 
      </Router>
    </div>
  )

}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // toggleLang: (lang) => dispatch(toggleLang(lang)),
    // loadingOn: () => dispatch(loadingOn()),
    // loadingOff: () => dispatch(loadingOff()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
