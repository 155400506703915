import React , {useState,useEffect}from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import Image3 from "../../images/home-nl-1-07.png";
import Image2 from "../../images/home-nl-2-07.png";
import Image1 from "../../images/home-nl-07.png";
import './homepage.scss'
import axios from "../../axios";
import { Link } from "react-router-dom";
import { Doughnut, Bar,  } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Silueta from '../../images/logo-only.png';
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import menudata from '../menu/menudata';
import Loader from "../loader/loader";
import {
    toggleLang,
    loadingOn,
    loadingOff
  
  } from "../../redux/Functions/actions";

const HomePage = (props) => {

const isLoading = props.isLoading;
// const [lang, setLang] = useState();
const [products, setproducts] = useState();
const [members, setmembers] = useState();
const [labels, setlabels] = useState();
const [partners, setpartners] = useState();
const [chartLabel, setChartLabels] = useState([]);
const [chartValues , setChartValues] = useState([]);
const [ mail, setMail] = useState();
const lang = props.lang;

const getProducts = () => {
    axios.get('/general/products/all')
    .then(data=>{
        console.log(data.data);
        setproducts(data.data);
        if (props.lang == 'al') {
            data?.data?.map((el)=>{
            return (
                setChartLabels(chartLabel => [...chartLabel, el.name_al]),
                setChartValues(chartValues => [...chartValues, el.capacity])
                )
            });
            
        }
        else {
            data?.data?.map((el)=>{
            return (setChartLabels(chartLabel => [...chartLabel, el.name_en]),
            setChartValues(chartValues => [...chartValues, el.capacity ]))
            });
            
        }
    })
    .catch(err => console.log(err));
}
// console.log('chartLabels',chartLabel);
// console.log('chartValues',chartValues);



const getMembers = () => {
    axios.get('/general/members/all')
    .then(data=>{
        console.log(data.data);
        setmembers(data.data);
    })
    .catch(err => console.log(err));
}
const getLabels = () => {
    axios.get('/general/translations/all')
    .then(data=>{
        // console.log(data.data);
        setlabels(data.data);
        // setLoading(false);
     setTimeout(()=>{
         console.log('ndodhi timeri')
        },300);    
    })
    .catch(err => console.log(err));
}
const getPartners = () => {
    axios.get('/general/partners/all')
    .then(data=>{
        console.log(data.data);
        setpartners(data.data);
    })
    .catch(err => console.log(err));
}

const translateKey = ( data, lang) => {
    if (lang == 'al') {
        let trans = labels != undefined &&  labels.length > 0  && labels?.filter(label => label?.key === data);
        return trans[0]?.al
    } else {
        let trans = labels != undefined &&  labels.length > 0  &&  labels?.filter(label => label?.key === data);
        return trans[0]?.en
    }
}

useEffect(()=>{
    window.localStorage.removeItem('auth');
    // setLang(window.localStorage.getItem('lang'));
    props.loadingOn();
    getProducts();
    getMembers();
    getPartners();
    getLabels();
    setTimeout(()=>{         
        props.loadingOff();
    },500);
  
},[]);

const name = (e) => {
    e.preventDefault();
    const ud = Object.assign({}, mail);
    ud.name = e.target.value ;
    setMail(ud);
}
const email = (e) => {
    e.preventDefault();
    const ud = Object.assign({}, mail);
    ud.email = e.target.value ;
    setMail(ud);
}
const message = (e) => {
    e.preventDefault();
    const ud = Object.assign({}, mail);
    ud.message = e.target.value ;
    setMail(ud);
}

const handleContactSubmit = (e) => {
    e.preventDefault();
    console.log('contatHandle', mail);
    let myMailOBJ = {
        mail: mail.email,
        msg: mail.message,
        name: mail.name
    }
    axios.post('/general/mail', myMailOBJ).then(data => console.log('emailData',data.data)).catch(err => console.log({error: err}))
    // setMail();
    
}

// console.log('mailData',mail);
// console.log('lang',lang);
// console.log('products',products);
// console.log('members',members);
// console.log('labels',labels);
// console.log('partners',partners);

const ChartData = {
    labels: chartLabel,
    datasets: [
      {
        labels: 'Ton per Month',
        data: chartValues ,
        backgroundColor: [
            'rgba(54, 162, 235, 0.9)',
            'rgba(255, 206, 86, 0.9)',
            'rgba(75, 192, 192, 0.9)',
            'rgba(153, 102, 255, 0.9)',
            'rgba(255, 99, 132, 0.9)',
          'rgba(155, 159, 64, 0.9)',
          'rgba(75, 22, 192, 0.9)',
          'rgba(123, 102, 255, 0.9)',
          'rgba(125, 159, 64, 0.9)',
          'rgba(123, 102, 255, 0.9)',
          'rgba(125, 232, 64, 0.9)',
          'rgba(33, 102, 255, 0.9)',
          'rgba(44, 159, 64, 0.9)',
          'rgba(159, 159, 122, 0.9)',
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 0,
      },
    ],
    options: {
      tooltips: {
         mode: 'index'
      }
   }
  };
  
  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
  }

if (props.isLoading) {
  return  <Loader />
} else

    return(
        <div className='homepage'>
          <div className='heading text-center w-100'>
              <div className='homeSlogan'>
              <h1 className=''>{translateKey('slogan',lang)}</h1>

              </div>
            <a className='homeAboutButton' href='#about' >{translateKey('homeAboutButton',lang)}</a>
            <a className='homeProductButton' href='#products'>{translateKey('homeProductButton',lang)}</a>
            </div>
            <Carousel 
                autoPlay={true} 
                infiniteLoop 
                showThumbs={false}
                showArrows={false}
                // dynamicHeight={false}
                
                >
                <div>
                    <img src={Image1} />
                </div>
                <div>
                    <img src={Image2} />
                </div>
                <div>
                    <img src={Image3} />
          
                </div>
                
            </Carousel>
            <section id='products' className='products p-3'>
                <h2 className='pageTitle'>{translateKey('productsTitle',lang)}</h2>
                <h6 className='pageSlogan'>{translateKey('productsSlogan',lang)}</h6>
                <div className="row p-3 mt-5">
                    {products?.map((el)=> {
                        return (<>
                            <div className='productListing row showme'>
                                {/* <div className='productPrice hideme'>
                                    <span>{lang === 'en' ? 'Price/KG' : 'Çmimi/KG'} </span>
                                    {'$' + el?.selling_price + ' '}
                                </div> */}
                                {/* <div className='productUpdate hideme'>
                                    {lang === 'en' ? 'Updated: ' : 'Përditësuar: '}  {el?.updated_at?.slice(0,10)}
                                </div> */}
                                <div className=' productImage'>
                                    <img src={process.env.REACT_APP_UPLOADS + el.pic_url} />
                                </div>
                               
                                <div className='productContent'>
                                    <h4 className='productName w-100'>{lang === 'al' ? el?.name_al : el?.name_en}</h4>
                                    
                                    <div className='productCapacity w-100'>
                                        {lang == 'en' ? 'Capacity' : 'Kapaciteti'} 
                                    </div>
                                    
                                    <div className='productCapacityValue'>
                                        {el.capacity + (lang == 'en' ? ' T/Month' : ' T/Muaj' )}
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
                
            </section>
            <section id='about' className='about'>
                <h2 className='pageTitle text-light'>{translateKey('aboutTitle',lang)}</h2>
                <h6 className='pageSlogan text-light'>{translateKey('aboutSlogan',lang)}</h6>
                <div className='row w-100 text-light aboutRow'>
                    <div className='col-md-3 col-12 aboutSlogan'>{translateKey('slogan',lang)}</div>
                    <div className='col-md-5 col-12 aboutContent'>{translateKey('about',lang)}</div>
                    <div className='col-md-4 col-12'>
                    <Doughnut data={ChartData}  />

                    </div>

                </div>
            </section>
            <section id='outTeam' className='outTeam'>
                <h2 className='pageTitle text-light'>{translateKey('ourteamTitle',lang)}</h2>
                <h6 className='pageSlogan text-light'>{translateKey('ourteamSlogan',lang)}</h6>
                <div className='row w-100 p-5 mx-auto '>
                    {members?.map((el)=> {
                        return(
                            <div className='memberListing'>
                                <div className='memberImage'><img src={el?.pic_url !== null ? process.env.REACT_APP_UPLOADS + el.pic_url : Silueta} alt={el.name}/></div>
                                <div className='memberContent'> 
                                    <h4>{el.name}</h4>
                                    <h6>{lang === 'en' ? el.position_en : el.position_al}</h6>
                                
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section id='contact' className="contact">
            <h2 className='pageTitle'>{translateKey('contactTitle',lang)}</h2>
                <h6 className='pageSlogan'>{translateKey('contactSlogan',lang)}</h6>
                <div className='row w-100 p-5 mx-auto '>
                    <div className="col-md-6 col-12">
                        <form onSubmit={handleContactSubmit}>
                            <label>{translateKey('contactNameLable',lang)}</label>
                            <input type={'text'} placeholder={translateKey('contactNamePlaceholder',lang)} onChange={name}></input>
                            
                            <label>{translateKey('contactEmailLabel',lang)}</label>
                            <input type={'email'} placeholder={translateKey('contactEmailPlaceholder',lang)} onChange={email}></input>
                            <label>{translateKey('contactMessageLabel',lang)}</label>
                            <textarea placeholder={translateKey('contactMessagePlaceholder',lang)} onChange={message}></textarea>
                            <button type="submit">{lang === 'en' ? 'Send': 'Dërgo'}</button>
                        </form>
                    </div>
                    <div className="col-md-6 col-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2936.7523878155835!2d21.210763015361003!3d42.602996779171335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549b84051cdae7%3A0xaba7345560c14ce5!2sG-Plus!5e0!3m2!1sen!2s!4v1640530568646!5m2!1sen!2s" width="100%" height="450" style={{borderRadius: '20px', border: '3px white solid'}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={handleRecaptcha}
                className='recaptcha'
                size={'invisible'}
                badge={'bottomleft'}
                theme={'dark'}
            />
                </div> 
            </section>
            <section id='footer' className="footer bg-dark text-light">
                <div className="container pt-3">
                   <div className="row w-100 text-left">
                       <div className="col-md-3 col-12" >
                           <b>Menu</b> <br/>
                           <ul className="pt-3">
                           {menudata?.map(el => {
                               return  <li><a href={el.url}> {lang == 'al' ? el.name_al : el.name_en}</a> </li>
                           })}
                            <li><a href='/login' key='login'>Login</a></li>

                           </ul>
                       </div>
                       <div className="col-md-6 col-12" >
                       <b>{lang == 'al' ? 'Kontakti' : 'Contact'}</b> <br/>
                       <ul className="pt-3">
                           <div className="row mb-2" >
                            <div className="col-2 text-center"><i className="text-center pl-1 pr-2 fa fa-phone  "/></div>
                            <div className="col-10"> <a href='tel:00 383 49 31 00 31'> 00 383 49 31 00 31</a></div>
                        </div>
                           <div className="row mb-2" >
                           <div className="col-2 text-center"> <i className="text-center pl-1 pr-2 fa fa-envelope "/></div>
                           <div className="col-10"> <a href='mailto:gplus.kosovo@gmail.com'> gplus.kosovo@gmail.com</a> </div>
                            </div>
                           <div className="row mb-2" >
                           <div className="col-2 text-center">   <i className="text-center pl-1 pr-2 fa fa-map-marker "/></div>
                           <div className="col-10"> <a href='#'>234 Kishnicë, Graçanicë, 10000, Kosove</a> </div>

                            </div>
                       </ul>
                       </div>
                       <div className="col-md-3 col-12 social" >
                       <b>{lang == 'al' ? 'Mediat Sociale' : 'Social Media'}</b> <br/>
                       <a href='http://www.fb.com' target={'_blank'}><i class="fa fa-facebook-square"></i></a>
                       <a href='http://www.linkedin.com' target={'_blank'}><i class="fa fa-linkedin-square"></i></a>
                       <a href='http://www.twitter.com' target={'_blank'}><i class="fa fa-twitter-square"></i></a>
                       </div>
                   </div>
                </div>
            </section>
        </div>
    )


}

const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading 
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      toggleLang: (lang) => dispatch(toggleLang(lang)),
      loadingOn: () => dispatch(loadingOn()),
      loadingOff: () => dispatch(loadingOff()),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);